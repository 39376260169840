import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function TriBalanceHeader(props) {
  const { StyledTableCell } = props;

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell key="accode">A/C Code</StyledTableCell>
        <StyledTableCell key="description">Description</StyledTableCell>
        <StyledTableCell key="debit">Debit</StyledTableCell>
        <StyledTableCell key="credit">Credit</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
