export const config = {
  //LIVE
  //apiUrl: "https://newhopeinternationaladminapi.church/",
  powerBiUrl: "https://hopeappapilive.newhopeinternational.church/",

  //NEW LIVE
  //apiUrl: "https://financeapi.newhopeinternational.church/",
  apiUrl: "https://hopeappapilive.newhopeinternational.church/",

  //Development
  //apiUrl: "https://newhopeinternationalapptest.church/",

  //STAGING
  //apiUrl: "https://localhost:44331/",
  //apiUrl: "https://localhost:7070/",

  //LIVE
};
