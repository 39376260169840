import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function CasesTableHeader(props) {
  debugger;
  const { valueToOrderBy, orderDirection, handleRequestSort, StyledTableCell } =
    props;

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell key="ref">
          <TableSortLabel
            active={valueToOrderBy === "ref"}
            direction={valueToOrderBy === "ref" ? orderDirection : "asc"}
            onClick={createSortHandler("ref")}
          >
            Reference
          </TableSortLabel>
        </StyledTableCell>

        <StyledTableCell key="description">
          <TableSortLabel
            active={valueToOrderBy === "description"}
            direction={
              valueToOrderBy === "description" ? orderDirection : "asc"
            }
            onClick={createSortHandler("description")}
          >
            Details
          </TableSortLabel>
        </StyledTableCell>
        {/* <StyledTableCell key="churchBranch">Church</StyledTableCell> */}
        <StyledTableCell key="amount">Amount</StyledTableCell>
        <StyledTableCell>Type</StyledTableCell>
        <StyledTableCell>Money Account</StyledTableCell>
        <StyledTableCell>Tax Code</StyledTableCell>
        <StyledTableCell key="paymentDate">Payment Date</StyledTableCell>
        <StyledTableCell>Edit</StyledTableCell>
        <StyledTableCell>Delete</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
