import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { registerLicense } from "@syncfusion/ej2-base";

import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import Theme from "./Resources/theme";
import Routes from "./routes";

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Ud0FiXX5YcXBXTmRe"
);

ReactDOM.render(
  <Provider store={store}>
    {/* <App /> */}
    <ThemeProvider theme={Theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
    ,
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
