import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const DownloadPage = ({ rootElementId, downloadFileName }) => {
  const downloadFileDocument = () => {
    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      let imgWidth = 210;
      let pageHeight = 297;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const pdf = new jsPDF("p", "mm");
      pdf.setFont("Helvetica");
      pdf.setFontSize(12);
      let position = 10;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        //position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`${downloadFileName}`);
    });
  };

  const createPdfDocument = () => {
    debugger;
    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const doc = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const divHeight = input.getBoundingClientRect().height;
      const imgHeight = (divHeight * 25.4) / 96;
      const pageCount = Math.ceil(imgHeight / pageHeight);

      //doc.addPage("l", "mm", "a4");
      doc.addImage(imgData, "PNG", 5, 7, pageWidth - 8, 0);

      if (pageCount > 0) {
        let j = 1;
        while (j !== pageCount) {
          doc.addPage("p", "mm", "a4");
          doc.addImage(imgData, "PNG", 5, -(287 * j), pageWidth - 8, 0);
          j++;
        }
      }

      doc.save(`${downloadFileName}`);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="mt-5"
    >
      <button onClick={() => createPdfDocument()} className="btn btn-primary">
        Download PDF
      </button>
    </div>
  );
};

export default DownloadPage;
