import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SLUGS from "../Resources/slugs";
import LoadingSpinner from "../Components/LoadingSpinner";

// Lazy load components
const LoginComponent = lazy(() => import("../pages/LoginAdminPage"));
const DashboardComponent = lazy(() => import("../pages/ReportDashboard"));
const UploadComponent = lazy(() => import("../pages/CaseUpload"));
const GaUploadComponent = lazy(() => import("../pages/GiftAidUploadPage"));
const CaseDetailComponent = lazy(() => import("../pages/CaseDetailPage"));
const CaseListComponent = lazy(() => import("../pages/ListCases"));
const DonorDetailsComponent = lazy(() => import("../pages/DonorsPage"));
const DonorsComponent = lazy(() => import("../pages/ListDonors"));
const MembersComponent = lazy(() => import("../pages/ChurchMembers"));
const TrialComponent = lazy(() => import("../pages/TrialBalance"));
const BalanceSheetComponent = lazy(() => import("../pages/BalanceSheet"));
const IncomeExpenditureComponent = lazy(() =>
  import("../pages/IncomeExpenditure")
);
const JournalComponent = lazy(() => import("../pages/JournalPage"));
const JournalListComponent = lazy(() => import("../pages/JournalList"));
const CaseDetailListComponent = lazy(() =>
  import("../pages/CaseDetailListPage")
);
const HmrcDonorsComponent = lazy(() => import("../pages/HmrcList"));
const PrincipalComponent = lazy(() => import("../pages/HomePage"));
const EditCaseJournalComponent = lazy(() => import("../pages/EditCasePage"));
const CreateMemberComponent = lazy(() => import("../pages/MembersPage"));
const AccountancyComponent = lazy(() => import("../pages/AccountancyPage"));
const OpenCloseComponent = lazy(() => import("../pages/OpenCloseYear"));
const NewBalanceSheetComponent = lazy(() => import("../pages/NewBalanceSheet"));

const routes = [
  { path: SLUGS.dashboard, component: DashboardComponent },
  { path: SLUGS.homepage, component: () => <div>homepage</div> },
  { path: SLUGS.dashboardHome, component: PrincipalComponent },
  { path: SLUGS.editJournalCase, component: EditCaseJournalComponent },
  { path: SLUGS.donorDetails, component: () => <div>Donor Details</div> },
  { path: SLUGS.donor, component: DonorDetailsComponent },
  { path: SLUGS.donors, component: DonorsComponent },
  { path: SLUGS.donorlist, component: DonorsComponent },
  { path: SLUGS.members, component: MembersComponent },
  { path: SLUGS.caseDetails, component: () => <div>caseDetails</div> },
  { path: SLUGS.caselist, component: CaseListComponent },
  { path: SLUGS.casedetail, component: CaseDetailComponent },
  { path: SLUGS.upload, component: UploadComponent },
  { path: SLUGS.gaUpload, component: GaUploadComponent },
  { path: SLUGS.memberDetails, component: () => <div>memberDetails</div> },
  { path: SLUGS.createMembers, component: CreateMemberComponent },
  { path: SLUGS.accountancy, component: AccountancyComponent },
  { path: SLUGS.trial, component: TrialComponent },
  { path: SLUGS.balanceSheet, component: BalanceSheetComponent },
  { path: SLUGS.incexpreport, component: IncomeExpenditureComponent },
  { path: SLUGS.journal, component: JournalComponent },
  { path: SLUGS.hmrcdonors, component: HmrcDonorsComponent },
  { path: SLUGS.casedetaillist, component: CaseDetailListComponent },
  { path: SLUGS.openclose, component: OpenCloseComponent },
  { path: SLUGS.test, component: NewBalanceSheetComponent },
  { path: SLUGS.journallist, component: JournalListComponent },
  { path: SLUGS.hmrc, component: JournalListComponent },
];

function PrivateRoutes() {
  const isAuthenticated = !!localStorage.getItem("token");

  return (
    <Suspense fallback={<LoadingSpinner loading />}>
      <Switch>
        {isAuthenticated ? (
          <>
            {routes.map(({ path, component: Component }, index) => (
              <Route key={index} exact path={path} component={Component} />
            ))}
            <Redirect to={SLUGS.dashboard} />
          </>
        ) : (
          <>
            <Route path={SLUGS.login} component={LoginComponent} />
            <Redirect to={SLUGS.login} />
          </>
        )}
      </Switch>
    </Suspense>
  );
}

export default PrivateRoutes;
