import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function JournalsTableHeader(props) {
  debugger;
  const { valueToOrderBy, orderDirection, handleRequestSort, StyledTableCell } =
    props;

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell key="transactiondetails">
          <TableSortLabel
            active={valueToOrderBy === "transactiondetails"}
            direction={
              valueToOrderBy === "transactiondetails" ? orderDirection : "asc"
            }
            onClick={createSortHandler("transactiondetails")}
          >
            Transaction Details
          </TableSortLabel>
        </StyledTableCell>

        <StyledTableCell key="account">
          <TableSortLabel
            active={valueToOrderBy === "account"}
            direction={valueToOrderBy === "account" ? orderDirection : "asc"}
            onClick={createSortHandler("account")}
          >
            Account
          </TableSortLabel>
        </StyledTableCell>
        <StyledTableCell key="accountName">Account Name</StyledTableCell>
        <StyledTableCell key="debit">Debit</StyledTableCell>
        <StyledTableCell key="credit">Credit</StyledTableCell>
        <StyledTableCell key="reference">Reference</StyledTableCell>
        <StyledTableCell key="paymentDate">Transaction Date</StyledTableCell>
        <StyledTableCell>Edit</StyledTableCell>
        <StyledTableCell>Delete</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
