import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadService } from "../services/data.service";
import { v1 as uuidv1 } from "uuid";
import LoadingSpinner from "../Components/LoadingSpinner";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FcDeleteRow, FcAbout } from "react-icons/fc";
import { history } from "../helpers/history";
import { ConvertToBase64 } from "../Components/ConvertToBase64";
import CurrencyInput from "react-currency-input-field";
import { FinancialYear } from "../Components/FinancialYear";

const GiftaidUpload = (props) => {
  debugger;
  toast.configure();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [caseDetail, setCaseDetail] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Fetch case details when formData is available
  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates after unmount

    if (formData && isMounted) {
      uploadService
        .getCaseById(formData.UploadId)
        .then((response) => {
          if (response && isMounted) {
            setCaseDetail(response);
          }
        })
        .catch((error) => {
          toast.error("Failed to load case details.");
        });
    }

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [formData]);

  // Reset form when formData changes
  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  // Handle form submission
  const handleSubmition = useCallback(async (data) => {
    debugger;
    const isValid = FinancialYear(data.PaymentDate);
    if (!isValid) {
      toast.error("Payment Date is not in the current financial year");
      return;
    }

    setIsLoading(true);

    let base64File = null;
    let fileName = null;

    if (data.uploadFile.length > 0) {
      try {
        const base64 = await ConvertToBase64(data.uploadFile);
        base64File = base64.split(",")[1];
        fileName = data.uploadFile[0].name;
      } catch (error) {
        toast.error("Failed to convert file to base64.");
        setIsLoading(false);
        return;
      }
    }

    const formData = {
      UploadId: uuidv1(),
      Ref: `NEW-${Math.random().toString(36).slice(2, 7)}`,
      Description: data.description,
      IsPaid: true,
      PaymentDate: data.PaymentDate,
      IsExpenditure: false,
      IsGiftAid: true,
      IsNonGiftAid: false,
      DateCreated: new Date(),
      FinancialDate: data.FinancialYearSelected,
      ChurchBranch: data.ChurchBranchSelected,
      Amount: data.Amount,
      DonorsId: data.DonorsSelected,
      TxtCodeId: data.TxtCodeSelected,
      CityId: data.ChurchBranchSelected,
      OrgId: "BCC44B30-6AB2-4969-B165-503CFDC9FE14",
      IsSentToHmrc: false,
      TaxCodeID: data.MoneyAccountSelected,
      image: base64File,
      AccountancyCloseId: 19,
      FileName: fileName,
    };

    setFormData(formData);

    try {
      const response = await uploadService.uploadCase(formData);
      setIsLoading(false);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to upload case.");
    }
  }, []);

  const goToDetails = (caseDetail) => {
    history.push("/casedetail", { caseDetail });
  };

  const goToDelete = (uploadId) => {};

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container>
          <form className="theme-form" onSubmit={handleSubmit(handleSubmition)}>
            {props.isMobile && (
              <div className="text-center">
                <h6>New Gift Aid Case</h6>
              </div>
            )}

            <div className="row">
              <div className="col-md-4">
                <Form.Label>Money Account</Form.Label>
                <Form.Control
                  as="select"
                  {...register("MoneyAccountSelected", {
                    required: true,
                  })}
                >
                  <option value="">Select Money Account</option>
                  {props.moneyAcc?.map((obj) => (
                    <option key={obj.taxCodeID} value={obj.taxCodeID}>
                      {obj.taxDescription}
                    </option>
                  ))}
                </Form.Control>
                {errors.MoneyAccountSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>

              <div className="col-md-4">
                <Form.Label>Donor</Form.Label>
                <Form.Control
                  as="select"
                  {...register("DonorsSelected", {
                    required: true,
                  })}
                >
                  <option value="">Select Donor</option>
                  {props.donors?.map((obj) => (
                    <option key={obj.donorsId} value={obj.donorsId}>
                      {obj.fullName}
                    </option>
                  ))}
                </Form.Control>
                {errors.DonorsSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>

              <div className="col-md-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  {...register("description", {
                    required: true,
                  })}
                />
                {errors.description && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <Form.Label>Tax Code</Form.Label>
                <Form.Control
                  as="select"
                  {...register("TxtCodeSelected", {
                    required: true,
                  })}
                >
                  <option value="">Select Tax Code</option>
                  {props.taxCode
                    .filter((item) => item.isTxtGiftAid === true)
                    .map((obj) => (
                      <option key={obj.txtCodeId} value={obj.txtCodeId}>
                        {obj.txtDescription}
                      </option>
                    ))}
                </Form.Control>
                {errors.TxtCodeSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>

              <div className="col-md-4">
                <Form.Label>Amount</Form.Label>
                <CurrencyInput
                  className="form-control"
                  inputMode="numeric"
                  placeholder="Amount"
                  {...register("Amount", {
                    required: true,
                  })}
                  decimalLimit={2}
                />
                {errors.Amount && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>

              <div className="col-md-4">
                <Form.Label>Payment Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Payment Date"
                  {...register("PaymentDate", {
                    required: true,
                  })}
                />
                {errors.PaymentDate && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <Form.Label>Church Branch</Form.Label>
                <Form.Control
                  as="select"
                  {...register("ChurchBranchSelected", {
                    required: true,
                  })}
                >
                  <option value="">Select Church Branch</option>
                  {props.branch.map((obj) => (
                    <option key={obj.cityId} value={obj.cityId}>
                      {obj.cityName}
                    </option>
                  ))}
                </Form.Control>
                {errors.ChurchBranchSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>

              <div className="col-md-4">
                <Form.Label>Upload File</Form.Label>
                <Form.Control type="file" {...register("uploadFile")} />
              </div>

              <div className="col-md-4">
                <Form.Label>Financial Year</Form.Label>
                <select
                  className="custom-select"
                  {...register("FinancialYearSelected", { required: true })}
                >
                  {props.financialYear.map((obj) => (
                    <option
                      key={obj.accountancyCloseId}
                      value={obj.accountancyCloseId}
                    >
                      {obj.financialYear}
                    </option>
                  ))}
                </select>
                {errors.FinancialYearSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
            </div>

            <div className="form-group mt-3">
              <Button
                variant="info"
                type="submit"
                disabled={isLoading}
                className="btn-block"
              >
                Submit
              </Button>
            </div>
          </form>
        </Container>
      )}
    </div>
  );
};

export default GiftaidUpload;
