import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import { Card, CardHeader, CardContent, Grid } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

export function CaseListShownChart(props) {
  let cardStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
  };
  let { shown, hidden } = props;

  const data = [
    { name: "shown", value: shown },
    { name: "notShown", value: hidden },
  ];

  const colorShown = "#FFBB28";
  const colorHidden = "#F0F0F0";
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const renderpiechart = (data) => {
    return (
      <PieChart width={165} height={165} style={{ marginBottom: "-80px" }}>
        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => {
            if (entry.name === "shown") {
              return <Cell key={`shown`} fill={colorShown} />;
            } else {
              return <Cell key={`notShown`} fill={colorHidden} />;
            }
          })}
        </Pie>
      </PieChart>
    );
  };

  return (
    <>
      <Card style={cardStyle}>
        <CardHeader
          title="Filtered Cases Shown"
          subheader="Within Selected Date Range"
        />
        <CardContent>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {renderpiechart(data)}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export function CaseListCompositionPie(props) {
  debugger;
  let cardStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
  };
  const { title, allCases, uniqueValues, attribute } = props;

  let valueArray = allCases;

  let data = [];

  if (uniqueValues.includes("Manchester")) {
    uniqueValues.forEach((val) => {
      let count = valueArray?.filter(
        (item) => item.churchBranch?.toUpperCase() === val.toUpperCase()
      ).length;
      data.push({ val, count });
    });
  } else {
    uniqueValues.forEach((val) => {
      let count = valueArray?.filter(
        (item) =>
          (item.isGiftAid === true
            ? "Gift Aid"
            : item.isNonGiftAid === true
            ? "Non-Gift Aid"
            : "Expenditure"
          ).toUpperCase() === val.toUpperCase()
      ).length;
      data.push({ val, count });
    });
  }

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${data[index].count}`}
      </text>
    );
  };

  return (
    <>
      <Card style={cardStyle}>
        <CardHeader title={title} subheader="Within Selected Date Range" />
        <CardContent>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PieChart
              width={165}
              height={165}
              style={{ marginBottom: "-80px" }}
            >
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                startAngle={180}
                endAngle={0}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="count"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </CardContent>
        <Grid container spacing={1} style={{ margin: 15 }}>
          {data.map((o, index) => {
            return (
              <Grid item xs={6} key={index}>
                <div>
                  <FiberManualRecordIcon style={{ color: COLORS[index] }} />
                  {o.val}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Card>
    </>
  );
}
