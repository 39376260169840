import React, { useContext, useEffect, useState } from "react";
import { string } from "prop-types";
import { useHistory } from "react-router-dom";
import { Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
import { SidebarContext } from "../../Hooks/useSidebar";
import SLUGS from "../../Resources/slugs";
import { IconBell, IconSearch } from "../../assets/icons";
import DropdownComponent from "../Dropdown/DropdownComponent";
import logo from "../../assets/prSantos.jpg";
import log2 from "../../assets/erica.jpeg";

const useStyles = createUseStyles((theme) => ({
  avatar: {
    height: 35,
    width: 35,
    minWidth: 35,
    borderRadius: 50,
    marginLeft: 14,
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    "@media (max-width: 768px)": {
      marginLeft: 14,
    },
  },
  container: {
    height: 40,
  },
  name: {
    ...theme.typography.itemTitle,
    textAlign: "right",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  separator: {
    borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
    marginLeft: 32,
    marginRight: 32,
    height: 32,
    width: 2,
    "@media (max-width: 768px)": {
      marginLeft: 14,
      marginRight: 0,
    },
  },
  title: {
    ...theme.typography.title,
    "@media (max-width: 1080px)": {
      marginLeft: 50,
    },
    "@media (max-width: 468px)": {
      fontSize: 20,
    },
  },
  iconStyles: {
    cursor: "pointer",
    marginLeft: 25,
    "@media (max-width: 768px)": {
      marginLeft: 12,
    },
  },
}));

function HeaderComponent() {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userDetail = user.user.userName.substring(
      0,
      user.user.userName.indexOf("@")
    );
    if (userDetail) {
      const newUser = userDetail.replaceAll(".", " ");
      const _user = newUser[0].toUpperCase`` + newUser.substring`1`;
      setUserName(
        _user === "Ericatrevizan" ? "Erica Trevizan" : "Ezequias Santos"
      );
    }
  }, [userName]);
  const { push } = useHistory();
  const { currentItem } = useContext(SidebarContext);
  const theme = useTheme();
  const classes = useStyles({ theme });

  let title;
  switch (true) {
    case currentItem === SLUGS.dashboard:
      title = "Dashboard";
      break;
    case [SLUGS.overview, SLUGS.overviewTwo, SLUGS.overviewThree].includes(
      currentItem
    ):
      title = "Overview";
      break;
    case currentItem === SLUGS.tickets:
      title = "Tickets";
      break;
    case [SLUGS.ideas, SLUGS.ideasTwo, SLUGS.ideasThree].includes(currentItem):
      title = "Ideas";
      break;
    case currentItem === SLUGS.contacts:
      title = "Contacts";
      break;
    case currentItem === SLUGS.agents:
      title = "Agents";
      break;
    case currentItem === SLUGS.articles:
      title = "Articles";
      break;
    case currentItem === SLUGS.subscription:
      title = "Subscription";
      break;
    case currentItem === SLUGS.settings:
      title = "Settings";
      break;
    default:
      title = "";
  }

  function onSettingsClick() {
    push(SLUGS.settings);
  }

  const PerformLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    push(SLUGS.login);
  };

  return (
    <Row
      className={classes.container}
      vertical="center"
      horizontal="space-between"
    >
      <span className={classes.title}>{title}</span>
      <Row vertical="center">
        <div className={classes.iconStyles}>
          <IconSearch />
        </div>
        <div className={classes.iconStyles}>
          <DropdownComponent
            label={<IconBell />}
            options={[
              {
                label: "Notification #1",
                onClick: () => console.log("Notification #1"),
              },
              {
                label: "Notification #2",
                onClick: () => console.log("Notification #2"),
              },
              {
                label: "Notification #3",
                onClick: () => console.log("Notification #3"),
              },
              {
                label: "Notification #4",
                onClick: () => console.log("Notification #4"),
              },
            ]}
            position={{
              top: 42,
              right: -14,
            }}
          />
        </div>
        <div className={classes.separator}></div>
        <DropdownComponent
          label={
            <>
              <span className={classes.name}>{userName}</span>
              {userName === "Ezequias Santos" ? (
                <img src={logo} alt="avatar" className={classes.avatar} />
              ) : userName === "Erica Trevizan" ? (
                <img src={log2} alt="avatar" className={classes.avatar} />
              ) : (
                ""
              )}
            </>
          }
          options={[
            {
              label: "Settings",
              onClick: onSettingsClick,
            },
            {
              label: "Logout",
              onClick: () => PerformLogOut(),
            },
          ]}
          position={{
            top: 52,
            right: -6,
          }}
        />
      </Row>
    </Row>
  );
}

HeaderComponent.propTypes = {
  title: string,
};

export default HeaderComponent;
