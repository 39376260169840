import React, { useState, useEffect, useCallback } from "react";
import TrialBalanceTableContent from "../Components/TableContent/TrialBalanceTableContent";
import LoadingSpinner from "../Components/LoadingSpinner";
import { uploadService } from "../services/data.service";
import OpenCloseFinancialYear from "../Components/OpenCloseYear";

const start = localStorage.getItem("startDate")
  ? new Date(parseInt(localStorage.getItem("startDate")))
  : null;
const end = localStorage.getItem("endDate")
  ? new Date(parseInt(localStorage.getItem("endDate")))
  : null;

const TrialBalance = () => {
  debugger;
  const startFinancialDate =
    start instanceof Date ? start : OpenCloseFinancialYear().start;
  const endFinancialDate =
    end instanceof Date ? end : OpenCloseFinancialYear().end;

  const [data, setData] = useState([]);
  const [dataObj, setDataObj] = useState([]);

  const fetchData = useCallback(() => {
    const getData = async () => {
      await uploadService
        .PostTrialBalanceData(startFinancialDate, endFinancialDate)
        .then((response) => {
          if (response.balance.length > 0) {
            setData(response.balance);
            setDataObj(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getData();
  }, [endFinancialDate, startFinancialDate]);

  useEffect(() => {
    const trialData = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(trialData);
  }, [fetchData]);

  return (
    <>
      {data.length > 0 ? (
        <div style={{ margin: "auto", width: "70%", height: "auto" }}>
          <div
            className="card-body"
            style={{ backgroundColor: "white", borderRadius: "25px" }}
          >
            <TrialBalanceTableContent
              startDate={startFinancialDate}
              endDate={endFinancialDate}
              uploadData={data}
              sumTotal={dataObj.sumTotal}
              sumTotalIncome={dataObj.sumTotalIncome}
              sumTotalExp={dataObj.sumTotalExp}
              sumUnrestrictedAccumulated={dataObj.unrestrictedFunds}
              sumUnrestrictedPeriod={dataObj.unrestrictedFundsPeriod}
              credicard={dataObj.creditcard}
              sumFixedAssets={dataObj.sumFixedAssets}
            />
          </div>
        </div>
      ) : (
        <div style={{ TextAlign: "center" }}>
          <LoadingSpinner
            style={{
              flex: 1,
              MarginTop: 240,
              JustifyContent: "center",
              AlignItems: "center",
            }}
          />
        </div>
      )}
    </>
  );
};

export default TrialBalance;
