import React from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";

export function ValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function FilterOnSearch(
  quickSearch,
  startDate,
  endDate,
  cases,
  donors,
  members,
  journals
) {
  debugger;
  const currentYear = new Date().getFullYear();
  const newDate = moment(new Date(`${currentYear - 5}-01-01`)).format(
    "YYYY-MM-DD kk:mm:ss"
  );
  const date1 = startDate
    ? moment(startDate.toString()).format("YYYY-MM-DD kk:mm:ss")
    : moment(newDate);
  const date2 = endDate
    ? moment(endDate.toString()).format("YYYY-MM-DD kk:mm:ss")
    : moment(new Date().toString()).format("YYYY-MM-DD kk:mm:ss");

  let filteredCases = [];
  if (donors) {
    filteredCases = donors.filter((o) => {
      if (
        //filtered by quickserach
        o.firstname?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.lastname?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.postcode?.toLowerCase().includes(quickSearch?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  } else if (donors && quickSearch) {
    filteredCases = cases.filter((o) => {
      if (
        o.ref?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.description?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        (o.isGiftAid
          ? "Gift Aid"
          : o.isNonGiftAid
          ? "Non-Gift Aid"
          : "Expenditure"
        )
          .toLowerCase()
          .includes(quickSearch?.toLowerCase()) ||
        o.amount
          .toString()
          ?.toLowerCase()
          .includes(quickSearch?.toLowerCase()) ||
        o.churchBranch.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        moment(o.paymentDate)
          .format("DD MMM YYYY")
          ?.toLowerCase()
          .includes(quickSearch?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    filteredCases = filteredCases.filter(
      (data) =>
        moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") >= date1 &&
        moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") <= date2
    );
  } else if (journals && quickSearch) {
    filteredCases = journals.filter((o) => {
      if (
        //filtered by quickserach
        o.transactionDetails
          ?.toLowerCase()
          .includes(quickSearch?.toLowerCase()) ||
        o.account?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.accountName?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.reference?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.paymentDate?.toLowerCase().includes(quickSearch?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  } else if (journals && journals.length > 0 && !quickSearch) {
    filteredCases = journals?.filter(
      (data) =>
        moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") >= date1 &&
        moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") <= date2
    );
  } else if (cases && quickSearch) {
    filteredCases = cases.filter((o) => {
      if (
        //filtered by quickserach
        o.ref?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.account?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.accountName?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.reference?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.paymentDate?.toLowerCase().includes(quickSearch?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  } else if (members && quickSearch) {
    filteredCases = members.filter((o) => {
      if (
        //filtered by quickserach
        o.firstname?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.lastname?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.postcode?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.contactNumber?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.church?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.ministry?.toLowerCase().includes(quickSearch?.toLowerCase()) ||
        o.bestTimeToContact
          ?.toLowerCase()
          .includes(quickSearch?.toLowerCase()) ||
        o.email?.toLowerCase().includes(quickSearch?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  } else {
    filteredCases = cases?.filter(
      (data) =>
        moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") >= date1 &&
        moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") <= date2
    );
  }
  return filteredCases;
}

export function FilterTrialBalanceOnSearch(startDate, endDate, trialBalance) {
  debugger;
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  let filteredCases = [];
  if (trialBalance.length > 0) {
    if (
      new Date(trialBalance.paymentDate) >= date1 &&
      new Date(trialBalance.paymentDate) <= date2
    ) {
      filteredCases = trialBalance;
      return filteredCases;
    }
    return filteredCases;
  }
  return filteredCases;
}

export function createEntry(title, data, isDate = false) {
  if (data) {
    if (isDate) {
      if (data === "0001-01-01T00:00:00" || data === "1970-01-01T00:00:00")
        return;
      else data = moment(data).format("Do MMM YYYY");
    }

    return (
      <Typography style={{ marginBottom: 5 }}>
        <b>{title}:</b>
        <br />
        {data}
      </Typography>
    );
  }
}

export function SearchGiftAid(startDate, endDate, cases) {
  debugger;
  const currentYear = new Date().getFullYear();
  const newDate = moment(new Date(`${currentYear - 5}-01-01`)).format(
    "YYYY-MM-DD kk:mm:ss"
  );
  const date1 = startDate
    ? moment(startDate.toString()).format("YYYY-MM-DD kk:mm:ss")
    : moment(newDate);
  const date2 = endDate
    ? moment(endDate.toString()).format("YYYY-MM-DD kk:mm:ss")
    : moment(new Date().toString()).format("YYYY-MM-DD kk:mm:ss");

  let filteredCases = [];

  filteredCases = cases.filter(
    (data) =>
      moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") >= date1 &&
      moment(data.paymentDate).format("YYYY-MM-DD kk:mm:ss") <= date2
  );
  return filteredCases;
}
