export async function ConvertToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file[0]);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    } else {
      resolve("");
    }
  });
}
