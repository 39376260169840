import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import LoadingSpinner from "../Components/LoadingSpinner";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FcDeleteRow, FcAbout } from "react-icons/fc";
import { Table } from "react-bootstrap";
import { uploadService } from "../services/data.service";

const DonorsListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [donors, setDonors] = useState([]);

  const listDonors = useCallback(() => {
    debugger;
    const getDonorList = async () => {
      setIsLoading(true);
      await uploadService
        .getAllDonors()
        .then((response) => {
          if (response) {
            setDonors(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setIsLoading(false);
    };
    getDonorList();
  }, []);
  useEffect(() => {
    if (!isLoading) {
      listDonors();
    }
  }, [isLoading, listDonors]);

  const goToDetails = (donor) => {};

  const goToDelete = (Id) => {};

  return (
    <div>
      <>
        <div>
          <div className="pagecontainer">
            <div className="container-fluid p-0">
              <h2>List of Donors</h2>
              {isLoading ? (
                <div className="pluswrap">
                  <div className="plus">
                    <LoadingSpinner />
                  </div>
                </div>
              ) : (
                <Container>
                  <Table striped bordered hover className="mt-3">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th scope="col" className="d-none d-sm-table-cell">
                          Last Name
                        </th>
                        <th scope="col" className="d-none d-sm-table-cell">
                          House Number
                        </th>
                        <th scope="col" className="d-none d-sm-table-cell">
                          Postcode
                        </th>
                        <th scope="col" className="d-none d-sm-table-cell">
                          Fullname
                        </th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    {donors.map((donor) => (
                      <tbody>
                        <tr key={donor.donorsId}>
                          <td>{donor.firstname}</td>
                          <td className="d-none d-sm-table-cell">
                            {donor.lastname}
                          </td>
                          <td className="d-none d-sm-table-cell">
                            {donor.houseNo}
                          </td>
                          <td>{donor.postcode}</td>
                          <td className="d-none d-sm-table-cell">
                            {donor.fullName}
                          </td>
                          <td>
                            <FcAbout onClick={() => goToDetails(donor)} />
                          </td>
                          <td>
                            <FcDeleteRow
                              onClick={() => goToDelete(donor.donorsId)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </Container>
              )}
            </div>
          </div>
        </div>
      </>
      <ToastContainer />
    </div>
  );
};

export default DonorsListPage;
