import React, { useState, useEffect, useCallback } from "react";
import JournalsTableContent from "../Components/TableContent/JournalsTableContent";
import { uploadService } from "../services/data.service";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import LoadingSpinner from "../Components/LoadingSpinner";
import OpenCloseFinancialYear from "../Components/OpenCloseYear";

const start = localStorage.getItem("startDate")
  ? new Date(parseInt(localStorage.getItem("startDate")))
  : null;
const end = localStorage.getItem("endDate")
  ? new Date(parseInt(localStorage.getItem("endDate")))
  : null;

const JournalList = () => {
  const startFinancialDate =
    start instanceof Date ? start : OpenCloseFinancialYear().start;
  const endFinancialDate =
    end instanceof Date ? end : OpenCloseFinancialYear().end;

  const [journals, setJournals] = useState([]);
  const [txtCodes, setTxtCode] = useState([]);
  const [moneyAccs, setMoneyAcc] = useState([]);

  const getData = useCallback(() => {
    const listJournals = async () => {
      const date1 = new Date(startFinancialDate);
      const date2 = new Date(endFinancialDate);
      await uploadService.getAllJournals(date1, date2).then((response) => {
        setJournals(response);
      });
    };
    const getMoneyAccList = async () => {
      uploadService.getMoneyAccount().then((accountResponse) => {
        if (accountResponse) {
          setMoneyAcc(accountResponse);
        }
      });
    };
    const getTxtCodeList = async () => {
      uploadService.getTaxCode().then((account2Response) => {
        if (account2Response) {
          setTxtCode(account2Response);
        }
      });
    };
    listJournals();
    getMoneyAccList();
    getTxtCodeList();
  }, [endFinancialDate, startFinancialDate]);

  useEffect(() => {
    const AllData = setTimeout(() => {
      getData();
    }, 500);

    return () => clearTimeout(AllData);
  }, [getData]);

  return (
    <div>
      <>
        {journals.length > 0 ? (
          <div>
            <div className="pagecontainer">
              <div className="container-fluid p-0">
                <h2 className="text-center">List of Journals</h2>
                <Container>
                  <JournalsTableContent
                    journals={journals}
                    txtCodes={txtCodes}
                    moneyAccs={moneyAccs}
                    startDate={startFinancialDate}
                    endDate={endFinancialDate}
                  />
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ TextAlign: "center" }}>
            <LoadingSpinner
              style={{
                flex: 1,
                MarginTop: 240,
                JustifyContent: "center",
                AlignItems: "center",
              }}
            />
          </div>
        )}
      </>
      <ToastContainer />
    </div>
  );
};

export default JournalList;
