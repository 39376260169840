import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from "@mui/material/InputLabel";
import MemberTableHeader from "../TableHead/MemebersTableHeader";
import { uploadService } from "../../services/data.service";
import DialogTitle from "@mui/material/DialogTitle";
import { v1 as uuidv1 } from "uuid";
import { FilterOnSearch } from "../Utility";
import { useHistory } from "react-router-dom";
import SLUGS from "../../Resources/slugs";

//Modal
import {
  Checkbox,
  IconButton,
  MenuItem,
  TableContainer,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";

//Boostrap Modal
import Form from "react-bootstrap/Form";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortedRowInformation = (rowArray, comparator) => {
  const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedRowArray.map((el) => el[0]);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const defaultClass = () => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "750px !important", // Set your width here
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px",
    width: "350px",
    textAlign: "center",
  },
}));

export default function MembersTableContent(props) {
  const history = useHistory();
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [member, setMember] = useState({});
  const classes = useStyles();
  const [members, setMembers] = useState(props.members);
  //form
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [postcode, setPostcode] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [church, setChurch] = useState("");
  const [ministry, setMinistry] = useState("");
  const [isBaptized, setIsBaptized] = useState(false);
  const [isBaptizedHolySpirit, setIsBaptizedHolySpirit] = useState(false);
  const [bestTimeContact, setBestTimeContact] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedHS, setIsCheckedHS] = useState(false);

  const {
    formState: { errors },
  } = useForm();

  const SearchEngine = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setMembers(props.members);
    } else {
      const searchResult = FilterOnSearch(
        value,
        null,
        null,
        null,
        null,
        props.members,
        null
      );
      if (searchResult.length > 0) {
        setMembers(searchResult);
      } else {
        setMembers([]);
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0);
  };

  const handleEdit = (e) => {
    const formData = {
      memberId: member.memberId,
      firstname: !firstname
        ? member.firstname
        : firstname === member.firstname
        ? member.firstname
        : firstname,
      lastname: !lastname
        ? member.lastname
        : lastname === member.lastname
        ? member.lastname
        : lastname,
      houseNo: !houseNo
        ? member.houseNo
        : houseNo === member.houseNo
        ? member.houseNo
        : houseNo,
      postcode: !postcode
        ? member.postcode
        : postcode === member.postcode
        ? member.postcode
        : postcode,
      fullName: !fullName
        ? member.fullName
        : fullName === member.fullName
        ? member.fullName
        : fullName,
      email: !email
        ? member.email
        : email === member.email
        ? member.email
        : email,
      contactNumber: !contactNumber
        ? member.contactNumber
        : contactNumber === member.contactNumber
        ? member.contactNumber
        : contactNumber,
      church: !church
        ? member.church
        : church === member.church
        ? member.church
        : church,
      ministry: !ministry
        ? member.ministry
        : ministry === member.ministry
        ? member.ministry
        : ministry,
      isBaptized: !isChecked ? member.isBaptized : isBaptized,
      isBaptizedHolySpirit: !isBaptizedHolySpirit
        ? member.isBaptizedHolySpirit
        : isBaptizedHolySpirit === member.isBaptizedHolySpirit
        ? member.isBaptizedHolySpirit
        : isBaptizedHolySpirit,
      bestTimeToContact: !bestTimeContact
        ? member.bestTimeToContact
        : bestTimeContact === member.bestTimeToContact
        ? member.bestTimeToContact
        : bestTimeContact,
    };
    debugger;
    uploadService
      .updateMember(formData)
      .then((response) => {
        setMember(response);
        setMemberId(memberId);
        setOpen(false);
        toast.success("Member updated sucessfully!");
        window.location.reload();
      })
      .catch((error) => {
        toast.error(`Error whilst updating member: ${error}`);
        window.location.reload();
      });
  };

  const handleOpen = (event, Id, member) => {
    setMemberId(Id);
    setOpen(true);
    setCreateOpen(false);
    setDeleteOpen(false);
    setMember(member);
  };

  const handleClose = () => {
    setOpen(false);
    setFirstName("");
    setLastName("");
    setHouseNo("");
    setPostcode("");
    setFullName("");
    setEmail("");
    setContactNumber("");
    setChurch("");
    setMinistry("");
    setIsBaptized(false);
    setIsBaptizedHolySpirit(false);
    setBestTimeContact("");
    setCreateOpen(false);
    setDeleteOpen(false);
  };

  const handleCreateOpen = () => {
    //form
    setFirstName("");
    setLastName("");
    setHouseNo("");
    setPostcode("");
    setFullName("");
    setEmail("");
    setContactNumber("");
    setChurch("");
    setMinistry("");
    setIsBaptized(false);
    setIsBaptizedHolySpirit(false);
    setBestTimeContact("");
    setCreateOpen(true);
    setDeleteOpen(false);
    setOpen(false);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  const handleCreate = (e, member) => {
    e.preventDefault();
    const memberForm = {
      MemberId: uuidv1(),
      Firstname: firstname,
      Lastname: lastname,
      HouseNo: houseNo,
      Postcode: postcode,
      FullName: `${firstname} ${lastname}`,
      Email: email,
      ContactNumber: contactNumber,
      Church: church,
      Ministry: ministry,
      IsBaptized: isBaptized,
      IsBaptizedHolySpirit: isBaptizedHolySpirit,
      BestTimeToContact: bestTimeContact,
    };
    uploadService
      .createMember(memberForm)
      .then((response) => {
        if (response.name === "SyntaxError") {
          toast.error(`Error whilst creating member: Please check form`);
        } else {
          setMember(response);
          setMemberId(memberId);
          toast.success("Member created sucessfully!");
          window.location.reload();
        }
      })
      .catch((error) => {
        debugger;
        toast.error(`Error whilst creating donor: ${error}`);
        window.location.reload();
      });
    setOpen(false);
  };

  const handleDelete = (member) => {
    debugger;
    uploadService
      .deleteMember(member.memberId)
      .then((response) => {
        toast.success("Member removed successfuly!");
        history.push(SLUGS.members);
      })
      .catch((error) => {
        toast.error(`Error whilst removing donor: ${error}`);
        history.push(SLUGS.members);
      });
    setDeleteOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setCreateOpen(false);
    setOpen(false);
  };
  const handleDeleteOpen = (member) => {
    setDeleteOpen(true);
    setCreateOpen(false);
    setOpen(false);
    setMember(member);
  };

  const handleChange = (event) => {
    setBestTimeContact(event.target.value);
  };

  const changeValue = (event) => {
    setHouseNo(event.target.value);
  };
  const changeIsBaptized = (event) => {
    setIsChecked(true);
    setIsBaptized(event.target.checked);
  };

  const changeIsBapitzedHS = (event) => {
    setIsCheckedHS(true);
    setIsBaptizedHolySpirit(event.target.checked);
  };

  return (
    <>
      <TableContainer>
        <div style={{ float: "left" }}>
          <IconButton type="text" onClick={(event) => handleCreateOpen(event)}>
            <AddCircleIcon
              htmlColor="green"
              style={{ width: "4rem", height: "4rem" }}
            />
          </IconButton>
        </div>
        <div
          style={{
            float: "left",
            marginLeft: "5rem",
            paddingRight: "10rem",
            minWidth: "10rem",
          }}
        >
          <FormControl
            sx={{ m: 1, minWidth: "sm", maxWidth: "md" }}
            variant="outlined"
          >
            <InputLabel id="church-label">Search by Group</InputLabel>
            <Select
              id="church"
              displayEmpty
              defaultValue={member.church}
              onChange={(event) => SearchEngine(event)}
              style={{ width: 200 }}
            >
              <MenuItem value={"All"}>All Members</MenuItem>
              <MenuItem value={"Portuguese"}>Portuguese</MenuItem>
              <MenuItem value={"English"}>English</MenuItem>
              <MenuItem value={"Spanish"}>Spanish</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{ float: "right" }}
          sx={{ m: 1, minWidth: "sm", maxWidth: "md" }}
        >
          <TextField
            onChange={(event) => SearchEngine(event)}
            variant="outlined"
            label="Search Box"
          />
        </div>

        <Table>
          <MemberTableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={handleRequestSort}
            StyledTableCell={StyledTableCell}
          />
          {sortedRowInformation(
            members,
            getComparator(orderDirection, valueToOrderBy)
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((member, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{member.houseNo}</StyledTableCell>
                <StyledTableCell>{member.postcode}</StyledTableCell>
                <StyledTableCell>{member.fullName}</StyledTableCell>
                <StyledTableCell>{member.email}</StyledTableCell>
                <StyledTableCell>{member.contactNumber}</StyledTableCell>
                <StyledTableCell>{member.church}</StyledTableCell>
                <StyledTableCell>{member.ministry}</StyledTableCell>
                <StyledTableCell>
                  {member.isBaptized === true ? "Yes" : "No"}
                </StyledTableCell>
                <StyledTableCell>{member.bestTimeToContact}</StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    size="small"
                    onClick={(event) =>
                      handleOpen(event, member.memberId, member)
                    }
                  >
                    <EditTwoToneIcon htmlColor="green" />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteOpen(member)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        component="div"
        count={members.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Modal onHide={(event) => handleClose(event)} show={open}>
        <Modal.Header>
          <Modal.Title>Edit Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              defaultValue={member.firstname}
              name="firstname"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              defaultValue={member.lastname}
              name="lastname"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>House No</Form.Label>
            <Form.Control
              type="text"
              placeholder="House No"
              defaultValue={member.houseNo}
              name="houseNo"
              onChange={(e) => setHouseNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Postcode</Form.Label>
            <Form.Control
              type="text"
              placeholder="Postcode"
              defaultValue={member.postcode}
              name="postcode"
              onChange={(e) => setPostcode(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              defaultValue={member.fullName}
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              defaultValue={member.email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact"
              defaultValue={member.contactNumber}
              name="contactNumber"
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>church</Form.Label>
            <select
              className="form-control"
              id="church"
              name="church"
              defaultValue={member.church}
              onChange={(e) => setChurch(e.target.value)}
              style={{ width: 200 }}
            >
              <option value={"Manchester"}>Manchester</option>
              <option value={"Barnsley"}>Barnsley</option>
              <option value={"York"}>York</option>
            </select>
          </Form.Group>
          <Form.Group>
            <Form.Label id="ministry-label">Ministry</Form.Label>
            <select
              className="form-control"
              id="ministry"
              name="ministry"
              defaultValue={member.ministry}
              onChange={(e) => setMinistry(e.target.value)}
              style={{ width: 200 }}
            >
              <option value={"Spanish"}>Spanish</option>
              <option value={"Portuguese"}>Portuguese</option>
              <option value={"English"}>English</option>
            </select>
          </Form.Group>
          <Form.Group>
            <Form.Label id="ministry-label">Is Baptized?</Form.Label>
            <Checkbox
              title="Is Baptized?"
              name="isBaptized"
              hintText="isBaptized"
              checked={isChecked ? isBaptized : member.isBaptized}
              onChange={(e) => changeIsBaptized(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label id="ministry-label">Is Baptized HS?</Form.Label>
            <Checkbox
              title="Is Baptized HS?"
              name="isBaptizedHolySpirit"
              hintText="isBaptizedHolySpirit"
              checked={
                isCheckedHS ? isBaptizedHolySpirit : member.isBaptizedHolySpirit
              }
              onChange={(e) => changeIsBapitzedHS(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Time to Contact?</Form.Label>
            <select
              className="form-control"
              id="bestTimeTocontact"
              defaultValue={member.bestTimeToContact}
              onChange={handleChange}
              style={{ width: 200 }}
            >
              <option value={"Morning"}>Morning</option>
              <option value={"Afternoon"}>Afternoon</option>
              <option value={"Evening"}>Evening</option>
            </select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={(event) => handleEdit(event)}
            className="btn btn-success"
          >
            Submit
          </button>
          <button
            type="text"
            onClick={(event) => handleClose(event)}
            className="btn btn-danger"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Dialog onClose={handleCreateClose} open={createOpen} maxWidth="sm">
        <DialogTitle>Create Member</DialogTitle>
        <Box className={classes.paper}>
          <form id="myForm">
            <div>
              <TextField
                label="First Name"
                name="firstname"
                hintText="First Name"
                defaultValue={member.firstname}
                onChange={(e) => setFirstName(e.target.value)}
                required={`This filesis required`}
              />
              {errors.firstname && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <TextField
                label="Last Name"
                name="lastname"
                hintText="Last Name"
                defaultValue={member.lastname}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              {errors.lastname && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <TextField
                label="House No"
                name="houseNo"
                hintText="House Number"
                defaultValue={member.houseNo}
                onChange={(e) => changeValue(e)}
                required
              />
              {errors.houseNo && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <TextField
                label="Postcode"
                name="postcode"
                hintText="Postcode"
                defaultValue={member.postcode}
                onChange={(e) => setPostcode(e.target.value)}
                required
              />
              {errors.postcode && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <TextField
                label="Email"
                name="email"
                hintText="Email"
                defaultValue={member.email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
              />
              {errors.email && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <TextField
                label="Contact Number"
                name="contactNumber"
                hintText="ContactNumber"
                defaultValue={member.contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                type="number"
                required
              />
              {errors.contactNumber && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: "sm", maxWidth: "md" }}
                required
              >
                <InputLabel id="church-label">Church</InputLabel>
                <Select
                  id="church"
                  defaultValue={member.church}
                  onChange={(e) => setChurch(e.target.value)}
                  style={{ width: 200 }}
                >
                  <MenuItem value={"Manchester"}>Manchester</MenuItem>
                  <MenuItem value={"Barnsley"}>Barnsley</MenuItem>
                  <MenuItem value={"York"}>York</MenuItem>
                </Select>
              </FormControl>
              {errors.church && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: "sm", maxWidth: "md" }}
                required
              >
                <InputLabel id="ministry-label">Ministry</InputLabel>
                <Select
                  id="ministry"
                  defaultValue={member.ministry}
                  onChange={(e) => setMinistry(e.target.value)}
                  style={{ width: 200 }}
                >
                  <MenuItem value={"Spanish"}>Spanish</MenuItem>
                  <MenuItem value={"Portuguese"}>Portuguese</MenuItem>
                  <MenuItem value={"English"}>English</MenuItem>
                </Select>
              </FormControl>
              {errors.ministry && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isBaptized"
                    hintText="isBaptized"
                    checked={member.isBaptized}
                    onChange={(e) => setIsBaptized(e.target.checked)}
                  />
                }
                label="Baptized?"
                sx={{ m: 1, minWidth: 200 }}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isBaptizedHolySpirit"
                    hintText="isBaptizedHolySpirit"
                    checked={member.isBaptizedHolySpirit}
                    onChange={(e) => setIsBaptizedHolySpirit(e.target.checked)}
                  />
                }
                label="Baptized HS?"
                sx={{ m: 1, minWidth: 200 }}
              />
            </div>
            <div>
              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: "sm", maxWidth: "md" }}
                required
              >
                <InputLabel id="bestTimeTocontact-label">
                  Time Contact
                </InputLabel>
                <Select
                  id="bestTimeTocontact"
                  defaultValue={member.bestTimeToContact}
                  onChange={handleChange}
                  style={{ width: 200 }}
                >
                  <MenuItem value={"Morning"}>Morning</MenuItem>
                  <MenuItem value={"Afternoon"}>Afternoon</MenuItem>
                  <MenuItem value={"Evening"}>Evening</MenuItem>
                </Select>
              </FormControl>
              {errors.bestTimeToContact && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div>
              <IconButton
                type="submit"
                onClick={(e) => handleCreate(e, member)}
              >
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
              <IconButton type="text" onClick={() => handleCreate(member)}>
                <CancelIcon htmlColor="red" fontSize="large" />
              </IconButton>
            </div>
          </form>
        </Box>
      </Dialog>

      <Dialog onClose={handleDeleteClose} open={deleteOpen}>
        <DialogTitle>Do you really want to remove this donor?</DialogTitle>
        <Box className={classes.paper}>
          <form id="myForm">
            <div>
              <TextField
                label="Full Name"
                name="fullName"
                hintText="Full Name"
                defaultValue={member.fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div>
              <IconButton
                color="primary"
                type="submit"
                onClick={() => handleDelete(member)}
              >
                <RemoveCircleIcon htmlColor="red" fontSize="large" />
              </IconButton>
              <IconButton
                color="primary"
                type="button"
                onClick={() => handleDeleteClose()}
              >
                <CancelIcon
                  htmlColor="blue"
                  fontSize="large"
                  style={{
                    paddingLeft: "20px",
                    width: "3.5rem",
                    height: "3.5rem",
                  }}
                />
              </IconButton>
            </div>
          </form>
        </Box>
      </Dialog>
    </>
  );
}
