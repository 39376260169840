import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { donorActions } from "../actions/donor.actions";
import LoadingSpinner from "../Components/LoadingSpinner";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { v1 as uuidv1 } from "uuid";

const { Label, Control } = { ...Form };

const DonorsPage = (props) => {
  const [payload, setPayload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (payload) {
      toast.success("Donor created successfully");
    }
  }, [payload]);

  useEffect(() => {
    reset(payload);
  }, [payload, reset]);

  const handleSubmition = async (data) => {
    setIsLoading(true);
    const Id = uuidv1();
    const formData = {
      DonorsId: Id,
      Firstname: data.firstName,
      Lastname: data.lastName,
      HouseNo: data.houseNo,
      Postcode: data.postcode,
      FullName: `${data.firstName} ${data.lastName}`,
    };

    const { dispatch } = props;
    dispatch(donorActions.createtDonor(formData));
    setPayload(formData);
    setIsLoading(false);
  };

  return (
    <div>
      <>
        <div>
          <div className="pagecontainer">
            <div className="container-fluid p-0">
              <h2>Create Donor</h2>
              {isLoading ? (
                <div className="pluswrap">
                  <div className="plus">
                    <LoadingSpinner />
                  </div>
                </div>
              ) : (
                <Container>
                  <form
                    className="theme-form"
                    onSubmit={handleSubmit(handleSubmition)}
                  >
                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>First Name</Label>
                        <Control
                          className="form-control"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          {...register("firstName", {
                            required: true,
                          })}
                        />
                        {errors.firstName && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>Last Name</Label>
                        <Control
                          className="form-control"
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          {...register("lastName", {
                            required: true,
                          })}
                        />
                        {errors.lastName && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>House Number</Label>
                        <Control
                          className="form-control"
                          type="text"
                          name="houseNo"
                          placeholder="House Number"
                          {...register("houseNo", {
                            required: true,
                          })}
                        />
                        {errors.houseNo && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>Postcode</Label>
                        <Control
                          className="form-control"
                          type="text"
                          name="postcode"
                          placeholder="Postcode"
                          {...register("postcode", {
                            required: true,
                          })}
                        />
                        {errors.postcode && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-3">
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </Container>
              )}
            </div>
          </div>
        </div>
      </>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  item: state.donor,
});

export default connect(mapStateToProps)(DonorsPage);
