import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function MemberTableHeader(props) {
  debugger;
  const { valueToOrderBy, orderDirection, handleRequestSort, StyledTableCell } =
    props;

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell key="houseNo">House No</StyledTableCell>
        <StyledTableCell key="postcode">Postcode</StyledTableCell>
        <StyledTableCell key="fullname">Full Name</StyledTableCell>
        <StyledTableCell key="email">Email</StyledTableCell>
        <StyledTableCell key="contact">Contact</StyledTableCell>
        <StyledTableCell key="church">Church</StyledTableCell>
        <StyledTableCell key="ministry">Ministry</StyledTableCell>
        <StyledTableCell key="isBaptized">Baptized?</StyledTableCell>
        {/*      <StyledTableCell key="isBaptizedHolySpirit">
          Baptized HS?
        </StyledTableCell> */}
        <StyledTableCell key="timeContact">Time Contact</StyledTableCell>
        <StyledTableCell>Edit</StyledTableCell>
        <StyledTableCell>Delete</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
