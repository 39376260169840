import React, { useState, useEffect } from "react";
import PieChart from "../Components/Charts/PieChart";
import BarChart from "../Components/Charts/BarChart";
import BarChart2 from "../Components/Charts/BarChart2";
import LineChart from "../Components/Charts/Linechart";
import { uploadService } from "../services/data.service";
import moment from "moment";
import LoadingSpinner from "../Components/LoadingSpinner";
//import { GetFinancialYear } from "../Components/FinancialYear";
import OpenCloseFinancialYear from "../Components/OpenCloseYear";

const HomePage = () => {
  debugger;
  const startFinancialDate = new Date(OpenCloseFinancialYear().start);
  const endFinancialDate = new Date(OpenCloseFinancialYear().end);
  const [gaCases, setGaCases] = useState([]);
  const [expCases, setExpCases] = useState([]);
  const [nogaCases, setNoGaCases] = useState([]);

  const [ga, setGa] = useState([]);
  const [exp, setExp] = useState([]);
  const [nonga, setNonGa] = useState([]);

  const [manAmountByYear, setManAmountByYear] = useState([]);
  const [barnsleyAmountByYear, setBarnsleyAmountByYear] = useState([]);
  const [yorkAmountByYear, setYorkAmountByYear] = useState([]);
  const [linechartData, setLinechartData] = useState([]);
  const [barchartData2, setBarchartData2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const start = moment(startFinancialDate.toString()).format(
    "YYYY-MM-DD kk:mm:ss"
  );
  const end = moment(endFinancialDate.toString()).format("YYYY-MM-DD kk:mm:ss");

  useEffect(() => {
    listCases();
  }, []);

  const listCases = async () => {
    setIsLoading(true);
    await uploadService.getAllCasesForBarChart(start, end).then((response) => {
      debugger;
      if (response) {
        setGaCases(response.gacases);
        setExpCases(response.expcases);
        setNoGaCases(response.nonGacases);
        setManAmountByYear(response.manchesterAmountByYear);
        setBarnsleyAmountByYear(response.barnsleyAmountByYear);
        setYorkAmountByYear(response.yorkAmountByYear);
        setLinechartData(response.lineChartData);
        setBarchartData2(response.lineChartData2);
        setGa(response.ga);
        setExp(response.exp);
        setNonGa(response.nonga);
        setIsLoading(false);
      }
    });
  };
  return (
    <>
      {isLoading ? (
        <div style={{ TextAlign: "center" }}>
          <LoadingSpinner
            style={{
              flex: 1,
              MarginTop: 240,
              JustifyContent: "center",
              AlignItems: "center",
            }}
          />
        </div>
      ) : (
        <div className="pagecontainer">
          <h5>{`Data from: ${moment(start.toString()).format(
            "DD/MM/YYYY"
          )} to ${moment(end.toString()).format("DD/MM/YYYY")}`}</h5>
          <div className="row">
            <div className="col-12 card">
              <BarChart
                gaCases={gaCases}
                expCases={expCases}
                nogaCases={nogaCases}
                startDate={start}
                endDate={end}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 card">
              <LineChart
                manchester={manAmountByYear}
                barnsley={barnsleyAmountByYear}
                york={yorkAmountByYear}
                lineChart={linechartData}
              />
            </div>
          </div>
          {/*   <div className="row justify-content-md-center mt-5 mb-5">
        <div className="col-12 card">
          <PieChart />
        </div>
      </div> */}

          <div className="row mt-5">
            <div className="col-12 card">
              <BarChart2 gaCases={ga} expCases={exp} nogaCases={nonga} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
