import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { uploadService } from "../services/data.service";
import { FcCurrencyExchange, FcDonate, FcDebt } from "react-icons/fc";
import GiftaidUpload from "./GiftAidUploadPage";
import ExpenseUpload from "./ExpenseUploadPage";
import NonGiftAidUpload from "./NonGiftAidPage";
import { IconButton } from "@material-ui/core";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";

const CaseUpload = ({ history }) => {
  const [moneyAccount, setMoneyAccount] = useState([]);
  const [donors, setDonors] = useState([]);
  const [taxCode, setTaxCode] = useState([]);
  const [churchBranch, setChurchBranch] = useState([]);
  const [finalYearList, setFinalYearList] = useState([]);
  const [isGA, setIsGA] = useState(false);
  const [isExp, setIsExp] = useState(false);
  const [isNoGA, setIsNoGA] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    // Fetch data
    const fetchData = async () => {
      try {
        const [
          donorResponse,
          financialYearResponse,
          branchResponse,
          moneyAccountResponse,
          taxCodeResponse,
        ] = await Promise.all([
          uploadService.getAllDonors(),
          uploadService.getFinancialyear(),
          uploadService.getBranches(),
          uploadService.getMoneyAccounts(),
          uploadService.gettaxCodes(),
        ]);

        if (isMounted) {
          setDonors(donorResponse);
          setFinalYearList(financialYearResponse);
          setChurchBranch(branchResponse);
          setMoneyAccount(moneyAccountResponse);
          setTaxCode(taxCodeResponse);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to load data. Please try again later.");
        }
      }
    };

    fetchData();

    // Attach resize listener
    window.addEventListener("resize", handleResize);

    return () => {
      isMounted = false; // Set to false when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 720);
  };

  const handleUploadTypeChange = (type) => {
    setIsGA(type === "GA");
    setIsExp(type === "Expense");
    setIsNoGA(type === "NoGA");
  };

  const handleCaseList = () => {
    history.push("/caselist");
  };

  return (
    <>
      <div className="pagecontainer">
        <div className="container-fluid p-0">
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="card mt-4">
                      <div className="card-body">
                        <IconButton
                          size="medium"
                          edge="start"
                          color="inherit"
                          aria-label="open drawer"
                          sx={{ mr: 2 }}
                          onClick={handleCaseList}
                          style={{ float: "left", marginBottom: "2rem" }}
                        >
                          <ReplyAllIcon htmlColor="blue" />
                        </IconButton>
                        <div className="text-center">
                          <h4>UPLOAD A CASE</h4>
                          <h6>
                            Please select which account the case belongs to
                          </h6>
                        </div>
                        <div className="div-container">
                          <div className="row">
                            {isMobile ? (
                              <>
                                <div className="col-4 text-center">
                                  <FcCurrencyExchange
                                    onClick={() =>
                                      handleUploadTypeChange("Expense")
                                    }
                                    className="clickable-icon"
                                  />
                                </div>
                                <div className="col-4 text-center">
                                  <FcDonate
                                    onClick={() => handleUploadTypeChange("GA")}
                                    className="clickable-icon"
                                  />
                                </div>
                                <div className="col-4 text-center">
                                  <FcDebt
                                    onClick={() =>
                                      handleUploadTypeChange("NoGA")
                                    }
                                    className="clickable-icon"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-4 text-center">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() =>
                                      handleUploadTypeChange("Expense")
                                    }
                                  >
                                    <FcCurrencyExchange />
                                    <span>Is Expense?</span>
                                  </button>
                                </div>
                                <div className="col-4 text-center">
                                  <button
                                    className="btn btn-info"
                                    type="button"
                                    onClick={() => handleUploadTypeChange("GA")}
                                  >
                                    <FcDonate />
                                    <span>Is GiftAid?</span>
                                  </button>
                                </div>
                                <div className="col-4 text-center">
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={() =>
                                      handleUploadTypeChange("NoGA")
                                    }
                                  >
                                    <FcDebt />
                                    <span>Is NonGA?</span>
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {/* Conditional Rendering */}
                        {isGA && (
                          <GiftaidUpload
                            donors={donors}
                            moneyAcc={moneyAccount}
                            taxCode={taxCode}
                            branch={churchBranch}
                            financialYear={finalYearList}
                            isMobile={isMobile}
                          />
                        )}
                        {isExp && (
                          <ExpenseUpload
                            moneyAcc={moneyAccount}
                            taxCode={taxCode}
                            branch={churchBranch}
                            financialYear={finalYearList}
                            isMobile={isMobile}
                          />
                        )}
                        {isNoGA && (
                          <NonGiftAidUpload
                            moneyAcc={moneyAccount}
                            taxCode={taxCode}
                            branch={churchBranch}
                            financialYear={finalYearList}
                            isMobile={isMobile}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default CaseUpload;
