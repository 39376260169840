import moment from "moment";

/**
 * Determines if a payment date falls within the current financial year.
 *
 * @param {string} paymentDate - The payment date in a format that can be parsed by moment.js.
 * @returns {boolean} True if the payment date is within the current financial year, false otherwise.
 */
export function FinancialYear(paymentDate) {
  const paymentMoment = moment(paymentDate);
  const financialYearStart = getFinancialYearStart(paymentMoment);
  const financialYearEnd = getFinancialYearEnd(paymentMoment);

  return paymentMoment.isBetween(
    financialYearStart,
    financialYearEnd,
    "days",
    "[]"
  );
}

/**
 * Calculates the start date of the financial year based on the given date.
 *
 * @param {moment.Moment} date - The date to calculate the financial year start from.
 * @returns {moment.Moment} The start date of the financial year.
 */
function getFinancialYearStart(date) {
  const year = date.year();
  const month = date.month();

  return moment(`${month > 7 ? year : year - 1}-08-01`);
}

/**
 * Calculates the end date of the financial year based on the given date.
 *
 * @param {moment.Moment} date - The date to calculate the financial year end from.
 * @returns {moment.Moment} The end date of the financial year.
 */
function getFinancialYearEnd(date) {
  const year = date.year();
  const month = date.month();

  return moment(`${month >= 1 && month <= 7 ? year + 1 : year}-07-31`);
}

export function GetFinancialYear() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  let startFinancialYear, endFinancialYear;

  if (currentMonth > 7 && currentMonth <= 12) {
    startFinancialYear = currentYear;
    endFinancialYear = currentYear + 1;
  } else if (currentMonth <= 7 && currentMonth >= 1) {
    startFinancialYear = currentYear - 1;
    endFinancialYear = currentYear;
  }

  const startFinancialDate = new Date(`${startFinancialYear}-08-01`);
  let start = moment(startFinancialDate.toString()).format(
    "YYYY-MM-DD kk:mm:ss"
  );

  const endFinancialDate = new Date(`${endFinancialYear}-07-31`);
  let end = moment(endFinancialDate.toString()).format("YYYY-MM-DD kk:mm:ss");

  return {
    start: start,
    end: end,
  };
}
