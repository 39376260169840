import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton, TableContainer } from "@material-ui/core";
import DonorsTableHeader from "../TableHead/DonorsTableHeader";
import { uploadService } from "../../services/data.service";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/Delete";
import { styled } from "@mui/material/styles";
import { v1 as uuidv1 } from "uuid";
import { FilterOnSearch } from "../Utility";
import { useHistory } from "react-router-dom";
import SLUGS from "../../Resources/slugs";

//Modal
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

//Boostrap Modal
import Form from "react-bootstrap/Form";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortedRowInformation = (rowArray, comparator) => {
  const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedRowArray.map((el) => el[0]);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px",
    width: "350px",
    textAlign: "center",
  },
}));

export default function DonorsTableContent(props) {
  const history = useHistory();

  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [donorsId, setDonorId] = useState("");
  const [donor, setDonor] = useState({});
  const classes = useStyles();
  const [donors, setDonors] = useState(props.donors);
  //form
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [postcode, setPostcode] = useState("");
  const [fullName, setFullName] = useState("");

  const SearchEngine = (event) => {
    const value = event.target.value;
    const searchResult = FilterOnSearch(
      value,
      null,
      null,
      null,
      props.donors,
      null
    );
    if (searchResult.length > 0) {
      setDonors(searchResult);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0);
  };

  const handleEdit = (e) => {
    const formData = {
      donorsId: donor.donorsId,
      firstname: !firstname
        ? donor.firstname
        : firstname === donor.firstname
        ? donor.firstname
        : firstname,
      lastname: !lastname
        ? donor.lastname
        : lastname === donor.lastname
        ? donor.lastname
        : lastname,
      houseNo: !houseNo
        ? donor.houseNo
        : houseNo === donor.houseNo
        ? donor.houseNo
        : houseNo,
      postcode: !postcode
        ? donor.postcode
        : postcode === donor.postcode
        ? donor.postcode
        : postcode,
      fullName: !fullName
        ? donor.fullName
        : fullName === donor.fullName
        ? donor.fullName
        : fullName,
    };

    uploadService
      .updateDonor(formData)
      .then((response) => {
        setDonor(response);
        setDonorId(donorsId);
        setOpen(false);
        toast.success("Donor updated sucessfully!");
        history.push(SLUGS.donorlist);
      })
      .catch((error) => {
        toast.error(`Error whilst updating donor: ${error}`);
        history.push(SLUGS.donorlist);
      });
  };

  const handleOpen = (event, Id, donor) => {
    setDonorId(Id);
    setOpen(true);
    setCreateOpen(false);
    setDeleteOpen(false);
    setDonor(donor);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateOpen = () => {
    //form
    setFirstName("");
    setLastName("");
    setHouseNo("");
    setPostcode("");
    setFullName("");
    setCreateOpen(true);
    setDeleteOpen(false);
    setOpen(false);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  const handleCreate = async () => {
    const donorForm = {
      DonorsId: uuidv1(),
      Firstname: firstname,
      Lastname: lastname,
      HouseNo: houseNo,
      Postcode: postcode,
      FullName: fullName,
    };
    await uploadService
      .createDonor(donorForm)
      .then((response) => {
        setDonor(response);
        setDonorId(donorsId);
        setOpen(false);
        setCreateOpen(false);
        toast.success("Donor created sucessfully!");
        history.push(SLUGS.donorlist);
      })
      .catch((error) => {
        toast.error(`Error whilst creating donor: ${error}`);
        history.push(SLUGS.donorlist);
      });
  };

  const handleDelete = async (donor) => {
    debugger;
    await uploadService
      .deleteDonor(donor.donorsId)
      .then((response) => {
        debugger;
        if (response.successMessage) {
          toast.success(response.successMessage);
          history.push(SLUGS.donorlist);
        } else {
          toast.error(response.errorMessage);
          history.push(SLUGS.donorlist);
        }
      })
      .catch((error) => {
        toast.error(`Error whilst removing donor: ${error}`);
        history.push(SLUGS.donorlist);
      });
    setDeleteOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setCreateOpen(false);
    setOpen(false);
  };
  const handleDeleteOpen = (donor) => {
    debugger;
    setDeleteOpen(true);
    setCreateOpen(false);
    setOpen(false);
    setDonor(donor);
  };

  return (
    <>
      <TableContainer>
        <div style={{ float: "left" }}>
          <IconButton type="text" onClick={(event) => handleCreateOpen(event)}>
            <AddCircleIcon
              htmlColor="green"
              style={{ width: "4rem", height: "4rem" }}
            />
          </IconButton>
        </div>
        <div style={{ float: "right" }}>
          <TextField
            onChange={(event) => SearchEngine(event)}
            variant="outlined"
            label="Search Box"
          />
        </div>

        <Table>
          <DonorsTableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={handleRequestSort}
            StyledTableCell={StyledTableCell}
          />
          {sortedRowInformation(
            donors,
            getComparator(orderDirection, valueToOrderBy)
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((donor, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{donor.firstname}</StyledTableCell>
                <StyledTableCell>{donor.lastname}</StyledTableCell>
                <StyledTableCell>{donor.houseNo}</StyledTableCell>
                <StyledTableCell>{donor.postcode}</StyledTableCell>
                <StyledTableCell>{donor.fullName}</StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    size="small"
                    onClick={(event) =>
                      handleOpen(event, donor.donorsId, donor)
                    }
                  >
                    <EditTwoToneIcon htmlColor="green" />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteOpen(donor)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        component="div"
        count={props.donors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Modal onHide={handleClose} show={open}>
        <Modal.Header>
          <Modal.Title>Edit Donor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              defaultValue={donor.firstname}
              name="firstname"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              defaultValue={donor.lastname}
              name="lastname"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>House No</Form.Label>
            <Form.Control
              type="text"
              placeholder="House No"
              defaultValue={donor.houseNo}
              name="houseNo"
              onChange={(e) => setHouseNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Postcode</Form.Label>
            <Form.Control
              type="text"
              placeholder="Postcode"
              defaultValue={donor.postcode}
              name="postcode"
              onChange={(e) => setPostcode(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              defaultValue={donor.fullName}
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={(event) => handleEdit(event)}
            className="btn btn-success"
          >
            Submit
          </button>
          <button
            type="text"
            onClick={(event) => handleClose(event)}
            className="btn btn-danger"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal onHide={handleCreateClose} show={createOpen}>
        <Modal.Header>
          <Modal.Title>Create Donor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>First Name</Form.Label>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="First Name"
              defaultValue={donor.firstname}
              name="firstname"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              defaultValue={donor.lastname}
              name="lastname"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>House No</Form.Label>
            <Form.Control
              type="text"
              placeholder="House No"
              defaultValue={donor.houseNo}
              name="houseNo"
              onChange={(e) => setHouseNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Postcode</Form.Label>
            <Form.Control
              type="text"
              placeholder="Postcode"
              defaultValue={donor.postcode}
              name="postcode"
              onChange={(e) => setPostcode(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              defaultValue={donor.fullName}
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={() => handleCreate(donor)}
            className="btn btn-success"
          >
            Submit
          </button>
          <button
            type="text"
            onClick={(event) => handleCreateClose(event)}
            className="btn btn-danger"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal onHide={handleDeleteClose} show={deleteOpen}>
        <Modal.Header>
          <Modal.Title>Do you really want to remove this donor?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              defaultValue={donor.fullName}
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={() => handleDelete(donor)}
            className="btn btn-primary"
          >
            Submit
          </button>
          <button
            type="text"
            onClick={() => handleDeleteClose()}
            className="btn btn-danger"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
