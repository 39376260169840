import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton, TableContainer } from "@material-ui/core";
import JournalsTableHeader from "../TableHead/JournalsHeader";
import { uploadService } from "../../services/data.service";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/Delete";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import { v1 as uuidv1 } from "uuid";
import { FilterOnSearch } from "../Utility";
import { Button } from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import { useHistory } from "react-router-dom";
import SLUGS from "../../Resources/slugs";

//Modal
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { toast } from "react-toastify";
import DatePicker from "../DatePickerPopper";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { GetFinancialYear } from "../../Components/FinancialYear";
import { FinancialYear } from "../../Components/FinancialYear";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortedRowInformation = (rowArray, comparator) => {
  const stabilizedRowArray = rowArray?.map((el, index) => [el, index]);
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedRowArray.map((el) => el[0]);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px",
    width: "350px",
    textAlign: "center",
  },
}));

export default function JournalsTableContent(props) {
  const history = useHistory();

  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [journalId, setJournalId] = useState("");
  const [journal, setJournal] = useState({});
  const classes = useStyles();
  const [journals, setJournals] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  //form
  const [transactionDetails, settransactionDetails] = useState("");
  const [account, setAccount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [debit, setDebit] = useState("");
  const [credit, setCredit] = useState("");
  const [reference, setReference] = useState("");
  const [paymentDate, setPaymentDate] = useState({});

  useEffect(() => {
    setJournals(props.journals);
  }, [props.journals]);

  const SearchEngine = (event) => {
    const value = event.target.value;
    const searchResult = FilterOnSearch(
      value,
      null,
      null,
      null,
      null,
      null,
      props.journals
    );
    if (searchResult.length > 0) {
      setJournals(searchResult);
    }
  };

  const changeState = (e) => {
    debugger;
    setStartDate(e[0][0].startDate);
    setEndDate(e[0][0].endDate);
    localStorage.setItem("startDate", new Date(e[0][0].startDate).getTime());
    localStorage.setItem("endDate", new Date(e[0][0].endDate).getTime());
    const dataResult = FilterOnSearch(
      null,
      new Date(e[0][0].startDate),
      new Date(e[0][0].endDate),
      null,
      null,
      null,
      props.journals
    );
    setDatePickerOpen(false);
    setJournals(dataResult);
  };

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    debugger;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    debugger;
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const formData = {
      journalTransId: journal.journalTransId,
      transactionDetails: !transactionDetails
        ? journal.transactionDetails
        : transactionDetails === journal.transactionDetails
        ? journal.transactionDetails
        : transactionDetails,
      account: !account
        ? journal.account
        : account === journal.account
        ? journal.account
        : account,
      accountName: !accountName
        ? journal.accountName
        : accountName === journal.accountName
        ? journal.accountName
        : accountName,
      debit: debit && debit !== journal.debit ? debit : journal.debit,
      credit: credit && credit !== journal.credit ? credit : journal.credit,
      reference: !reference
        ? journal.reference
        : reference === journal.reference
        ? journal.reference
        : reference,
      paymentDate:
        moment(new Date(paymentDate)).isValid() === false
          ? moment(new Date(journal.paymentDate)).format("YYYY-MM-DDThh:mm:ssZ")
          : moment(new Date(paymentDate)).format("YYYY-MM-DDThh:mm:ssZ"),
    };
    debugger;
    uploadService
      .editJournal(formData)
      .then((response) => {
        setJournal(response);
        setJournalId(response.JournalTransId);
        setOpen(false);
        history.push(SLUGS.journallist);
        toast.success("Journal updated sucessfully!");
      })
      .catch((error) => {
        toast.error(`Error whilst updating journal: ${error}`);
        history.push(SLUGS.journallist);
      });
  };

  const handleOpen = (event, Id, journal) => {
    setJournalId(Id);
    setOpen(true);
    setCreateOpen(false);
    setDeleteOpen(false);
    setJournal(journal);
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentDate(dateForPicker(journal.paymentDate));
  };

  const handleCreateOpen = () => {
    history.push(SLUGS.journal);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  const handleCreate = () => {
    const journalForm = {
      JournalTransId: uuidv1(),
      TransactionDetails: transactionDetails,
      Account: account,
      AccountName: accountName,
      Debit: debit,
      Credit: credit,
      Reference: reference,
      paymentDate: new Date(),
    };
    uploadService
      .createJournal(journalForm)
      .then((response) => {
        setJournal(response);
        setJournalId(response.journalTransId);
        setOpen(false);
        toast.success("Journal created sucessfully!");
      })
      .catch((error) => {
        toast.error(`Error whilst creating journal: ${error}`);
        history.push(SLUGS.journallist);
      });
  };

  const handleDelete = (journal) => {
    debugger;
    uploadService
      .deleteJournal(journal.journalTransId)
      .then((response) => {
        toast.success("Journal removed successfuly!");
        history.push(SLUGS.journallist);
      })
      .catch((error) => {
        toast.error(`Error whilst removing journal: ${error}`);
        history.push(SLUGS.journallist);
      });
    setDeleteOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setCreateOpen(false);
    setOpen(false);
  };
  const handleDeleteOpen = (journal) => {
    setDeleteOpen(true);
    setCreateOpen(false);
    setOpen(false);
    setJournal(journal);
  };
  const handleDatePickerOpen = (event) => {
    setDatePickerOpen(true);
  };
  const handlePickerClose = (event) => {
    setDatePickerOpen(false);
  };
  const dateForPicker = (dateString) => {
    return moment(new Date(dateString)).format("YYYY-MM-DD");
  };
  const handlePaymentDateChange = (event) => {
    const _date = dateForPicker(event.target.value);
    setPaymentDate(_date);
  };

  const onChange = (event) => {
    debugger;
    const newValue = event.target.value;
    setAccount(newValue);
  };

  return (
    <>
      <TableContainer>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              style={{
                backgroundColor: "#498f91",
                color: "#323232",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                type="text"
                onClick={() => handleCreateOpen()}
              >
                <AddCircleIcon
                  htmlColor="yellow"
                  style={{ width: "3rem", height: "3rem" }}
                />
              </IconButton>

              <Button
                variant="contained"
                style={{
                  backgroundColor: "#a7d7d9",
                  fontWeight: "bold",
                  width: "30%",
                  height: "auto",
                }}
                type="button"
                onClick={() => handleDatePickerOpen()}
              >
                {`From ${moment(startDate.toString()).format(
                  "DD/MM/YYYY"
                )} to ${moment(endDate.toString()).format("DD/MM/YYYY")}`}
              </Button>

              <TextField
                onChange={(event) => SearchEngine(event)}
                variant="outlined"
                placeholder="Search..."
                style={{
                  backgroundColor: "#a7d7d9",
                  borderRadius: "5px",
                  color: "black",
                }}
              />
            </Toolbar>
          </AppBar>
        </Box>

        <Table>
          <JournalsTableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={() => handleRequestSort()}
            StyledTableCell={StyledTableCell}
          />
          {sortedRowInformation(
            journals,
            getComparator(orderDirection, valueToOrderBy)
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((journal, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{journal.transactionDetails}</StyledTableCell>
                <StyledTableCell>{journal.account}</StyledTableCell>
                <StyledTableCell>{journal.accountName}</StyledTableCell>
                <StyledTableCell>{journal.debit.toFixed(2)}</StyledTableCell>
                <StyledTableCell>{journal.credit.toFixed(2)}</StyledTableCell>
                <StyledTableCell>{journal.reference}</StyledTableCell>
                <StyledTableCell>
                  {moment(journal.paymentDate).format("DD/MM/YYYY")}
                </StyledTableCell>
                {FinancialYear(journal.paymentDate) ? (
                  <>
                    <StyledTableCell>
                      {!journal.reference.includes("JOURNAL") ? (
                        <IconButton
                          disabled
                          size="small"
                          onClick={(event) =>
                            handleOpen(event, journal.journalTransId, journal)
                          }
                        >
                          <EditTwoToneIcon htmlColor="green" />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={(event) =>
                            handleOpen(event, journal.journalTransId, journal)
                          }
                        >
                          <EditTwoToneIcon htmlColor="green" />
                        </IconButton>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {!journal.reference.includes("JOURNAL") ? (
                        <IconButton
                          disabled
                          size="small"
                          onClick={() => handleDeleteOpen(journal)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteOpen(journal)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell>
                      <IconButton
                        disabled
                        size="small"
                        onClick={(event) =>
                          handleOpen(event, journal.journalTransId, journal)
                        }
                      >
                        <EditTwoToneIcon htmlColor="light green" />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        disabled
                        size="small"
                        onClick={() => handleDeleteOpen(journal)}
                      >
                        <DeleteIcon color="light red" />
                      </IconButton>
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            ))}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        component="div"
        count={journals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Modal onHide={handleClose} show={open}>
        <Modal.Header>
          <Modal.Title>
            <h2>Edit Journal</h2>
            <p className="text-danger">Please edit both journal entries</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Transaction Details</Form.Label>
            <Form.Control
              type="text"
              name="transactionDetails"
              defaultValue={journal.transactionDetails}
              onChange={(e) => settransactionDetails(e.target.value)}
            />
          </Form.Group>
          {journal.credit === 0 ? (
            <Form.Group>
              <Form.Label>Account</Form.Label>
              <Form.Control
                as="select"
                name="account"
                value={
                  account && account !== journal.account
                    ? account
                    : journal.account
                }
                onChange={(e) => onChange(e)}
              >
                {props.txtCodes?.map((taxAcc, index) => (
                  <option key={index} value={taxAcc.txtCode}>
                    {taxAcc.txtDescription}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          ) : (
            <Form.Group>
              <Form.Label>Account</Form.Label>
              <Form.Control
                as="select"
                name="account"
                value={
                  account && account !== journal.account
                    ? account
                    : journal.account
                }
                onChange={(e) => onChange(e)}
              >
                {props.moneyAccs?.map((moneyAcc, index) => (
                  <option key={index} value={moneyAcc.taxCode}>
                    {moneyAcc.taxDescription}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Debit</Form.Label>
            <Form.Control
              type="text"
              name="debit"
              defaultValue={journal.debit}
              onChange={(e) => setDebit(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Credit</Form.Label>
            <Form.Control
              type="text"
              name="credit"
              defaultValue={journal.credit}
              onChange={(e) => setCredit(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Reference</Form.Label>
            <Form.Control
              type="text"
              name="reference"
              defaultValue={journal.reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Journal Date</Form.Label>
            <Form.Control
              type="date"
              name="paymentDate"
              value={
                moment(new Date(paymentDate)).isValid() === false
                  ? dateForPicker(journal.paymentDate)
                  : dateForPicker(paymentDate)
              }
              onChange={(event) => handlePaymentDateChange(event)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={(event) => handleEdit(event)}
            className="btn btn-success"
          >
            Submit
          </button>
          <button
            type="text"
            onClick={(event) => handleClose(event)}
            className="btn btn-danger"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Dialog onClose={() => handleCreateClose()} open={createOpen}>
        <DialogTitle>Create Journal</DialogTitle>
        <Box className={classes.paper}>
          <form id="myForm">
            <div>
              <TextField
                label="Trnasaction Details"
                name="transactionDetails"
                hintText="Transaction Details"
                defaultValue={journal.transactionDetails}
                onChange={(e) => settransactionDetails(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Account"
                name="account"
                hintText="Account"
                defaultValue={journal.account}
                onChange={(e) => setAccount(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Account Name"
                name="accountName"
                hintText="Account Name"
                defaultValue={journal.accountName}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Debit"
                name="debit"
                hintText="Debit"
                defaultValue={journal.debit}
                onChange={(e) => setDebit(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Credit"
                name="credit"
                hintText="Credit"
                defaultValue={journal.credit}
                onChange={(e) => setCredit(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Reference"
                name="reference"
                hintText="Reference"
                defaultValue={journal.reference}
                onChange={(e) => setReference(e.target.value)}
              />
            </div>
            <div>
              <DatePicker
                label="Transaction Date"
                name="paymentDate"
                hintText="Transaction Date"
                defaultValue={journal.paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
              />
            </div>
            <div>
              <IconButton type="submit" onClick={() => handleCreate(journal)}>
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
              <IconButton type="text" onClick={() => handleCreate(journal)}>
                <CancelIcon htmlColor="red" fontSize="large" />
              </IconButton>
            </div>
          </form>
        </Box>
      </Dialog>

      <Dialog onClose={() => handleDeleteClose()} open={deleteOpen}>
        <DialogTitle>Do you really want to remove this journal?</DialogTitle>
        <Box className={classes.paper}>
          <form id="myForm">
            <div>
              <TextField
                label="journalTransId"
                name="journalTransId"
                hintText="journalTransId"
                defaultValue={journal.journalTransId}
                onChange={(e) => settransactionDetails(e.target.value)}
              />
            </div>
            <div>
              <IconButton
                color="primary"
                type="submit"
                onClick={() => handleDelete(journal)}
              >
                <RemoveCircleIcon htmlColor="red" fontSize="large" />
              </IconButton>
              <IconButton
                color="primary"
                type="textt"
                onClick={() => handleDeleteClose()}
              >
                <CancelIcon
                  htmlColor="blue"
                  fontSize="large"
                  style={{
                    paddingLeft: "20px",
                    width: "3.5rem",
                    height: "3.5rem",
                  }}
                />
              </IconButton>
            </div>
          </form>
        </Box>
      </Dialog>

      <Dialog
        onClose={() => handlePickerClose()}
        open={datePickerOpen}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Search cases by date</DialogTitle>
        <Box className={classes.paper}>
          <DatePicker dataArray={(event) => changeState(event)} />
        </Box>
      </Dialog>
    </>
  );
}
