import { generalConstants } from "../constants";

export function donors(state = {}, action) {
  switch (action.type) {
    case generalConstants.DONOR_ALL_REQUEST:
      return {
        loading: true,
      };
    case generalConstants.DONOR_ALL_SUCCESS:
      return {
        items: action.donors,
      };
    case generalConstants.DONOR_ALL_FAILURE:
      return {
        error: action.error,
      };
    case generalConstants.DONOR_REQUEST:
      return {
        item: action.donor,
      };
    case generalConstants.DONOR_SUCCESS:
      return {
        item: action.donor,
      };
    case generalConstants.DONOR_FAILURE:
      return {
        error: action.error,
      };
    case generalConstants.DONOR_UPDATE_REQUEST:
      return {
        item: action.donor,
      };
    case generalConstants.DONOR_UPDATE_SUCCESS:
      return {
        item: action.donor,
      };
    case generalConstants.DONOR_UPDATE_FAILURE:
      return {
        error: action.error,
      };
    case generalConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((donor) =>
          donor.donorsId === action.id ? { ...donor, deleting: true } : donor
        ),
      };
    case generalConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter((donor) => donor.donorsId !== action.id),
      };
    case generalConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((donor) => {
          if (donor.donorsId === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = donor;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return donor;
        }),
      };
    default:
      return state;
  }
}
