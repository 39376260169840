import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import PowerBIReport from "../Components/PowerBEReport.tsx";

const ReportDashboard = (props) => {
  const useStyles = makeStyles((theme) => ({
    Paper: {
      background: theme.palette.type === "light" ? "#fff" : "#474747",
    },
    dashboardContainer: {
      height: "calc(100vh - 100px)",
    },
  }));
  const classes = useStyles(props.theme);
  return (
    <Box className={classes.dashboardContainer}>
      <PowerBIReport dashboardName="newhope_v2" {...props} />
    </Box>
  );
};

export default ReportDashboard;
