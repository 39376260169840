import * as React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  Highlight,
  SeriesDirective,
  Inject,
  Tooltip,
  DateTime,
  SplineAreaSeries,
  Legend,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";

const SAMPLE_CSS = `
      .control-fluid {
          padding: 0px !important;
      }`;
/**
 * Area sample
 */
const LineChart = (props) => {
  debugger;
  return (
    <div className="control-pane">
      <style>{SAMPLE_CSS}</style>
      <div className="control-section">
        <ChartComponent
          id="line-charts"
          style={{ textAlign: "center" }}
          primaryXAxis={{
            valueType: "DateTime",
            labelFormat: "y",
            majorGridLines: { width: 0 },
            intervalType: "Years",
            minimum: new Date(2017, 0, 1),
            maximum: new Date(2023, 0, 1),
            edgeLabelPlacement: "Shift",
          }}
          primaryYAxis={{
            labelFormat: "{value}%",
            lineStyle: { width: 0 },
            maximum: 100,
            interval: 10,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
          load={load.bind(this)}
          width={Browser.isDevice ? "100%" : "75%"}
          legendSettings={{ enableHighlight: true }}
          chartArea={{ border: { width: 0 } }}
          title="Church Income in Percentage"
          loaded={onChartLoad.bind(this)}
          tooltip={{ enable: true }}
        >
          <Inject
            services={[SplineAreaSeries, DateTime, Tooltip, Legend, Highlight]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={props?.lineChart?.map((item) => ({
                x: new Date(`${item.x}`, 0, 1),
                y: +item.man,
              }))}
              xName="x"
              yName="y"
              name="Manchester"
              marker={{
                visible: true,
                isFilled: true,
                height: 6,
                width: 6,
                shape: "Circle",
              }}
              opacity={0.5}
              type="SplineArea"
              width={2}
              border={{ width: 2 }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={props?.lineChart?.map((item) => ({
                x: new Date(`${item.x}`, 0, 1),
                y: +item.barnsley,
              }))}
              xName="x"
              yName="y"
              name="Barnsley"
              marker={{
                visible: true,
                isFilled: true,
                height: 7,
                width: 7,
                shape: "Diamond",
              }}
              opacity={0.5}
              type="SplineArea"
              width={2}
              border={{ width: 2 }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={props?.lineChart?.map((item) => ({
                x: new Date(`${item.x}`, 0, 1),
                y: +item.york,
              }))}
              xName="x"
              yName="y"
              name="York"
              marker={{
                visible: true,
                isFilled: true,
                height: 8,
                width: 8,
                shape: "Circle",
              }}
              opacity={0.5}
              type="SplineArea"
              width={2}
              border={{ width: 2 }}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
  function onChartLoad(args) {
    let chart = document.getElementById("line-charts");
    chart.setAttribute("title", "");
  }
  function load(args) {
    let selectedTheme = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    ).replace(/-dark/i, "Dark");
  }
};
export default LineChart;
