import React, { useCallback, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton, TableContainer, Grid } from "@material-ui/core";
import { uploadService } from "../../services/data.service";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DeleteIcon from "@material-ui/icons/Delete";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import { FilterOnSearch } from "../Utility";
import { Button } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";

import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DatePickerPopper from "../../Components/DatePickerPopper";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  CaseListShownChart,
  CaseListCompositionPie,
} from "../Charts/CaseListPie";
import SLUGS from "../../Resources/slugs";

//Modal
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { toast } from "react-toastify";
import CasesTableHeader from "../TableHead/CasesTableHeader";
import { FinancialYear } from "../../Components/FinancialYear";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortedRowInformation = (rowArray, comparator) => {
  const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedRowArray.map((el) => el[0]);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ebebeb",
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px",
    width: "350px",
    textAlign: "center",
  },
}));

export default function CasesTableContent(props) {
  debugger;

  const history = useHistory();

  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [caseDetails, setCaseDetails] = useState({});
  const classes = useStyles();
  const [cases, setCases] = useState(props.cases);
  const [allCases, setAllCases] = useState(props.cases);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  //Edit Form
  const [caseRef, setRef] = useState(caseDetails.ref);
  const [description, setDescription] = useState(caseDetails.description);
  const [churchBranch, setChurchBranch] = useState(caseDetails.churchBranch);
  const [paymentDate, setPaymentDate] = useState(
    new Date(caseDetails.paymentDate)
  );
  const [amount, setAmount] = useState(caseDetails.amount);
  const [caseType, setCaseType] = useState("");
  const [moneyAcc, setMoneyAcc] = useState([]);
  const [txtCodeAcc, setTxtCodeAcc] = useState([]);
  const [churchBranches, setChurchBranches] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);

  const getCasesDetails = useCallback(() => {
    const getData1 = () => {
      setCaseTypes(["Gift Aid", "Non-Gift Aid", "Expenditure"]);
      setCaseType(
        caseDetails.isGiftAid === true
          ? "ga"
          : caseDetails.isNonGiftAid === true
          ? "nonga"
          : "exp"
      );
      const getChurchDetails = () => {
        setChurchBranches(["Manchester", "Barnsley", "York"]);
      };
      getChurchDetails();
    };

    const getMoneyAcc = async () => {
      debugger;
      await uploadService.getMoneyAccounts().then((response) => {
        if (response) {
          setMoneyAcc(response);
        }
      });
    };

    const getTxtCodeAcc = async () => {
      await uploadService.gettaxCodes().then((response) => {
        if (response) {
          setTxtCodeAcc(response);
        }
      });
    };
    getData1();
    getMoneyAcc();
    getTxtCodeAcc();
  }, [caseDetails.isGiftAid, caseDetails.isNonGiftAid]);

  useEffect(() => {
    const AllData = setTimeout(() => {
      getCasesDetails();
    }, 500);

    return () => clearTimeout(AllData);
  }, [getCasesDetails]);

  const SearchEngine = (event) => {
    debugger;
    const value = event.target.value;
    const searchResult = FilterOnSearch(
      value,
      startDate,
      endDate,
      props.cases,
      null,
      null,
      null
    );
    if (searchResult.length > 0) {
      setCases(searchResult);
    } else {
      setCases([]);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0);
  };

  const handleEdit = (e) => {
    debugger;
    const formData = {
      uploadId: caseDetails.donorsId,
      ref: caseRef ? caseRef : caseDetails.ref,
      description: description ? description : caseDetails.description,
      isPaid: caseDetails.isPaid,
      paymentDate:
        paymentDate.getDate().toString() === "NaN"
          ? caseDetails.paymentDate
          : paymentDate,
      isExpenditure:
        caseType === "exp" ? caseDetails.isExpenditure === true : false,
      isGiftAid: caseType === "ga" ? caseDetails.isGiftAid === true : false,
      isNonGiftAid:
        caseType === "nonga" ? caseDetails.isNonGiftAid === true : false,
      cityId: churchBranch ? churchBranch : caseDetails.cityId,
      donorsId: caseDetails.donorsId,
      fileName: caseDetails.fileName,
      filePath: caseDetails.filePath,
      taxCodeID: caseDetails.taxCodeID,
      txtCodeId: caseDetails.txtCodeId,
      financialDate: caseDetails.financialDate,
      dateCreated: caseDetails.dateCreated,
      churchBranch: churchBranch ? churchBranch : caseDetails.churchBranch,
      amount: amount ? amount : caseDetails.amount,
      txtCode: caseDetails.txtCode,
    };

    uploadService
      .updateCase(formData)
      .then((response) => {
        setCaseDetails(response);
        setUpdateId(updateId);
        setOpen(false);
        toast.success("Case updated sucessfully!");
        history.push(SLUGS.caselist);
      })
      .catch((error) => {
        toast.error(`Error whilst updating case: ${error}`);
        history.push(SLUGS.caselist);
      });
  };

  const handleOpen = (event, Id, caseDetails) => {
    setUpdateId(Id);
    setOpen(true);
    setDeleteOpen(false);
    setCaseDetails(caseDetails);
  };

  const handleCaseDetail = (event, Id, caseDetails, moneyAcc, txtCode) => {
    debugger;
    history.push(SLUGS.casedetail, {
      state: { caseDetails: caseDetails, moneyAcc: moneyAcc, txtCode: txtCode },
    });
    /* history.push(SLUGS.editJournalCase, {
      state: { caseDetails: caseDetails, moneyAcc: moneyAcc, txtCode: txtCode },
    }); */
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setOpen(false);
  };
  const handleDeleteOpen = (caseDetails, event) => {
    event.preventDefault();
    setDeleteOpen(true);
    setOpen(false);
    setCaseDetails(caseDetails);
  };

  const handleChange = (event) => {
    setCaseType(event.target.value);
  };

  const handlePaymentDateChange = (event) => {
    setPaymentDate(event.target.value);
  };

  const handleDelete = (event, donor) => {
    debugger;
    event.preventDefault();
    uploadService
      .deleteCase(caseDetails.uploadId)
      .then((response) => {
        toast.success("Case removed successfuly!");
        history.push(SLUGS.upload);
      })
      .catch((error) => {
        toast.error(`Error whilst removing case: ${error}`);
        history.push(SLUGS.upload);
      });
    setDeleteOpen(false);
  };

  const handleDatePickerOpen = (event) => {
    debugger;
    setDatePickerOpen(true);
  };

  const changeState = (e) => {
    debugger;
    setStartDate(e[0][0].startDate);
    setEndDate(e[0][0].endDate);
    const startDate = new Date(e[0][0].startDate + 1);
    const endDate = new Date(e[0][0].endDate + 1);
    localStorage.setItem("startDate", startDate.getTime());
    localStorage.setItem("endDate", endDate.getTime());

    const dataResult = FilterOnSearch(
      null,
      new Date(e[0][0].startDate),
      new Date(e[0][0].endDate),
      props.cases,
      null,
      null,
      null
    );
    setDatePickerOpen(false);
    setCases(dataResult);
  };

  const handlePickerClose = (event) => {
    setDatePickerOpen(false);
  };

  const handleCreateOpen = () => {
    history.push(SLUGS.upload);
  };

  return (
    <>
      <TableContainer>
        <AppBar position="static">
          <Toolbar
            style={{
              backgroundColor: "#498f91",
              color: "#323232",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              type="text"
              onClick={() => handleCreateOpen()}
            >
              <AddCircleIcon
                htmlColor="yellow"
                style={{ width: "3rem", height: "3rem" }}
              />
            </IconButton>

            <Button
              variant="contained"
              style={{
                backgroundColor: "#a7d7d9",
                fontWeight: "bold",
                width: "30%",
                height: "auto",
              }}
              type="button"
              onClick={() => handleDatePickerOpen()}
            >
              {`From ${moment(startDate.toString()).format(
                "DD/MM/YYYY"
              )} to ${moment(endDate.toString()).format("DD/MM/YYYY")}`}
            </Button>

            <TextField
              onChange={(event) => SearchEngine(event)}
              variant="outlined"
              placeholder="Search..."
              style={{
                backgroundColor: "#a7d7d9",
                borderRadius: "5px",
                color: "black",
              }}
            />
          </Toolbar>
        </AppBar>

        <Grid item xs={12} style={{ marginTop: 30 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <CaseListShownChart
                shown={cases.length}
                hidden={allCases.length - cases.length}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <CaseListCompositionPie
                title="Cases by Church Branch"
                allCases={cases}
                uniqueValues={churchBranches}
                attribute="branchCleansed"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <CaseListCompositionPie
                title="Case Type Composition"
                allCases={cases}
                uniqueValues={caseTypes}
                attribute="caseType"
              />
            </Grid>
          </Grid>
        </Grid>

        <Table className="mt-3">
          <CasesTableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={handleRequestSort}
            StyledTableCell={StyledTableCell}
          />
          {sortedRowInformation(
            cases,
            getComparator(orderDirection, valueToOrderBy)
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((caseDetail, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{caseDetail.ref}</StyledTableCell>
                <StyledTableCell>{caseDetail.description}</StyledTableCell>
                {/* <StyledTableCell>{caseDetail.churchBranch}</StyledTableCell> */}
                <StyledTableCell>
                  {caseDetail.amount.toFixed(2)}
                </StyledTableCell>
                <StyledTableCell>
                  {caseDetail.isGiftAid
                    ? "Gift Aid"
                    : caseDetail.isNonGiftAid
                    ? "Non-Gift Aid"
                    : "Expenditure"}
                </StyledTableCell>
                <StyledTableCell>
                  {caseDetail.moneyAccountDescription}
                </StyledTableCell>
                <StyledTableCell>
                  {caseDetail.txtCodeDescription}
                </StyledTableCell>
                <StyledTableCell>
                  {moment(caseDetail.paymentDate).format("DD/MM/YYYY")}
                </StyledTableCell>
                {FinancialYear(caseDetail.paymentDate) ? (
                  <>
                    <StyledTableCell>
                      <IconButton
                        size="small"
                        onClick={(event) =>
                          // handleOpen(event, caseDetail.uploadId, caseDetail)
                          handleCaseDetail(
                            event,
                            caseDetail.uploadId,
                            caseDetail,
                            moneyAcc,
                            txtCodeAcc
                          )
                        }
                      >
                        <EditTwoToneIcon htmlColor="green" />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        size="small"
                        onClick={(e) => handleDeleteOpen(caseDetail, e)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell>
                      <IconButton
                        size="small"
                        onClick={(event) =>
                          // handleOpen(event, caseDetail.uploadId, caseDetail)
                          handleCaseDetail(
                            event,
                            caseDetail.uploadId,
                            caseDetail,
                            moneyAcc,
                            txtCodeAcc
                          )
                        }
                        disabled
                      >
                        <EditTwoToneIcon htmlColor="light green" />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        size="small"
                        onClick={(e) => handleDeleteOpen(caseDetail, e)}
                        disabled
                      >
                        <DeleteIcon color="light red" />
                      </IconButton>
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            ))}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        component="div"
        count={cases.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Edit Case</DialogTitle>
        <Box className={classes.paper}>
          <form id="myForm">
            <div>
              <TextField
                label="Reference"
                name="ref"
                hintText="Reference"
                defaultValue={caseDetails.ref}
                onChange={(e) => setRef(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Details"
                name="description"
                hintText="Details"
                defaultValue={caseDetails.description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Church"
                name="churchBranch"
                hintText="Church"
                defaultValue={caseDetails.churchBranch}
                onChange={(e) => setChurchBranch(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Amount"
                name="amount"
                hintText="Amount"
                defaultValue={caseDetails.amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div>
              <Select
                labelId="caseType"
                id="caseTypeId"
                defaultValue={caseType}
                label="Case Type"
                onChange={handleChange}
                style={{
                  width: "50%",
                  height: "20%",
                  marginLeft: "-12%",
                  marginTop: 4,
                }}
              >
                <MenuItem value={"ga"}>Gift Aid</MenuItem>
                <MenuItem value={"nonga"}>Non-Gift Aid</MenuItem>
                <MenuItem value={"exp"}>Expenditure</MenuItem>
              </Select>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date desktop"
                  inputFormat="MM/dd/yyyy"
                  value={caseDetails.paymentDate}
                  onChange={handlePaymentDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div>
              <IconButton type="submit" onClick={(event) => handleEdit(event)}>
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
              <IconButton type="text" onClick={(event) => handleClose(event)}>
                <CancelIcon htmlColor="red" fontSize="large" />
              </IconButton>
            </div>
          </form>
        </Box>
      </Dialog>

      <Dialog
        onClose={handlePickerClose}
        open={datePickerOpen}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Search cases by date</DialogTitle>
        <Box className={classes.paper}>
          <DatePickerPopper dataArray={(event) => changeState(event)} />
        </Box>
      </Dialog>

      <Dialog onClose={handleDeleteClose} open={deleteOpen}>
        <DialogTitle>Do you really want to remove this donor?</DialogTitle>
        <Box className={classes.paper}>
          <form id="myForm">
            <div>
              <TextField
                label="Reference"
                name="ref"
                hintText="Reference"
                defaultValue={caseDetails.ref}
                onChange={(e) => setRef(e.target.value)}
              />
            </div>
            <div>
              <IconButton
                color="primary"
                type="submit"
                onClick={(e) => handleDelete(e, caseDetails)}
              >
                <RemoveCircleIcon htmlColor="red" fontSize="large" />
              </IconButton>
              <IconButton
                color="primary"
                type="textt"
                onClick={() => handleDeleteClose()}
              >
                <CancelIcon
                  htmlColor="blue"
                  fontSize="large"
                  style={{
                    paddingLeft: "20px",
                    width: "3.5rem",
                    height: "3.5rem",
                  }}
                />
              </IconButton>
            </div>
          </form>
        </Box>
      </Dialog>
    </>
  );
}
