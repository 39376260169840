import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userActions } from "../actions";
import ChurchIcon from "@material-ui/icons/Layers";
import LoadingSpinner from "../Components/LoadingSpinner";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadService } from "../services/data.service";

const LoginAdminPage = ({ dispatch, loggingIn, user, codePayload }) => {
  const [state, setState] = useState({
    username: '',
    password: '',
    submitted: false,
    isDialogOpend: false,
    isCodeEmailDialog: false,
    code: '',
    newPassword: '',
    confirmNewPassword: '',
    email: '',
    emailTocode: '',
    errorMessage: '',
  });

  useEffect(() => {
    // Reset login status on component mount
    dispatch(userActions.logout());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, submitted: true }));
    const { username, password } = state;
    if (username && password) {
      dispatch(userActions.login(username, password));
    }
  };

  const goToForgotPassword = () => {
    setState((prevState) => ({
      ...prevState,
      isCodeEmailDialog: true,
    }));
  };

  const handleEmailCodeClose = () => {
    setState((prevState) => ({
      ...prevState,
      isCodeEmailDialog: false,
    }));
  };

  const submitToGetCode = async (e) => {
    e.preventDefault();
    const { emailTocode } = state;
    if (emailTocode) {
      await dispatch(userActions.getPasswordCode(new Date(), emailTocode));
      handleEmailCodeClose();
      setState((prevState) => ({
        ...prevState,
        isDialogOpend: true,
      }));
    }
  };

  const handleDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      isDialogOpend: false,
    }));
  };

  const handleDialogSubmit = async (e) => {
    e.preventDefault();
    const { code, newPassword, email, confirmNewPassword } = state;
    const startDate = localStorage.getItem('startDate');
    const date1 = new Date();
    const date2 = new Date(JSON.parse(startDate));
    if (code && code === codePayload?.code && date1 < date2) {
      if (newPassword === confirmNewPassword) {
        await dispatch(userActions.resetPassword(newPassword, email));
        toast.success('Password updated successfully!', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        handleDialogClose();
      } else {
        setState((prevState) => ({
          ...prevState,
          errorMessage: 'Passwords do not match',
        }));
        toast.error('Passwords do not match', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        errorMessage: 'Invalid code or code expired',
      }));
      toast.error('Invalid code or code expired', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const goToRemoveAccount = async (e) => {
    e.preventDefault();
    const { username, password } = state;
    const response = await uploadService.removeMobileAppUser(username, password);
    if (response.successMessage === 'User account removed successfully') {
      toast.success(response.successMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(response.errorMessage, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const { username, password, submitted, email, newPassword, confirmNewPassword, code, emailTocode, errorMessage } = state;

  return (
    <section className="container animated fadeInUp">
      <div className="row" style={{ margin: 'auto' }}>
        <div className="col-md-6 offset-md-3">
          <div id="login-wrapper">
            <header>
              <div className="brand">
                <a href="/" className="logo">
                  <ChurchIcon />
                  <span>NEW HOPE </span>INTERNATIONAL
                </a>
              </div>
            </header>
            <div className="panel panel-primary">
              <div className="panel-heading">
                <h3 className="panel-title">Sign In</h3>
              </div>
              <div className="panel-body">
                <p>Login to access your account.</p>
                <form name="form" onSubmit={handleSubmit}>
                  <div className={`form-group ${submitted && !username ? 'has-error' : ''}`}>
                    <div className="col-md-12">
                      <label htmlFor="username">Username</label>
                      <TextField
                        type="text"
                        fullWidth
                        name="username"
                        value={username}
                        onChange={handleChange}
                      />
                      {submitted && !username && (
                        <div className="help-block text-danger">Username is required</div>
                      )}
                    </div>
                  </div>
                  <div className={`form-group ${submitted && !password ? 'has-error' : ''}`}>
                    <div className="col-md-12">
                      <label htmlFor="password">Password</label>
                      <TextField
                        type="password"
                        fullWidth
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />
                      {submitted && !password && (
                        <div className="help-block text-danger">Password is required</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-md-12">
                      <button className="btn btn-primary btn-block">Login</button>
                      {loggingIn && (
                        <div style={{ textAlign: 'center' }}>
                          <LoadingSpinner />
                        </div>
                      )}
                      <hr />
                      <button
                        type="button"
                        className="btn btn-info btn-block"
                        onClick={goToForgotPassword}
                      >
                        Forgot your password?
                      </button>
                      <button
                        className="btn btn-success btn-block"
                        onClick={goToRemoveAccount}
                      >
                        Remove your account
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        onClose={handleDialogClose}
        open={state.isDialogOpend}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          Check your email, paste code here
        </DialogTitle>
        <div style={{ margin: 'auto' }}>
          {errorMessage && (
            <p className="text-danger" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {errorMessage}
            </p>
          )}
        </div>
        <div style={{ margin: 'auto' }}>
          <form id="myCodeForm">
            <div className={`form-group ${!code ? 'has-error' : ''}`}>
              <div>
                <TextField
                  label="Code"
                  name="code"
                  fullWidth
                  value={code}
                  onChange={handleChange}
                />
                {!code && (
                  <div className="help-block text-danger">Code is required</div>
                )}
              </div>
            </div>
            <div className={`form-group ${!email ? 'has-error' : ''}`}>
              <div>
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  value={email}
                  onChange={handleChange}
                />
                {!email && (
                  <div className="help-block text-danger">Email is required</div>
                )}
              </div>
            </div>
            <div className={`form-group ${!newPassword ? 'has-error' : ''}`}>
              <div>
                <TextField
                  label="New Password"
                  name="newPassword"
                  type="password"
                  fullWidth
                  value={newPassword}
                  onChange={handleChange}
                />
                {!newPassword && (
                  <div className="help-block text-danger">New Password is required</div>
                )}
              </div>
            </div>
            <div className={`form-group ${!confirmNewPassword || newPassword !== confirmNewPassword ? 'has-error' : ''}`}>
              <div>
                <TextField
                  label="Confirm New Password"
                  name="confirmNewPassword"
                  type="password"
                  fullWidth
                  value={confirmNewPassword}
                  onChange={handleChange}
                />
                {(!confirmNewPassword || newPassword !== confirmNewPassword) && (
                  <div className="help-block text-danger">
                    { !confirmNewPassword ? 'Confirm Password is required' : 'Passwords do not match' }
                  </div>
                )}
              </div>
            </div>
            <div>
              <IconButton
                type="submit"
                onClick={handleDialogSubmit}
              >
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
              <IconButton
                onClick={handleDialogClose}
              >
                <CancelIcon htmlColor="red" fontSize="large" />
              </IconButton>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        onClose={handleEmailCodeClose}
        open={state.isCodeEmailDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Insert Code Email Here</DialogTitle>
        <div style={{ margin: 'auto' }}>
          <form id="myEmailCodeForm">
            <div className={`form-group ${!emailTocode ? 'has-error' : ''}`}>
              <div>
                <TextField
                  label="Email"
                  name="emailTocode"
                  fullWidth
                  value={emailTocode}
                  onChange={handleChange}
                />
                {!emailTocode && (
                  <div className="help-block text-danger">Email is required</div>
                )}
              </div>
            </div>
            <div style={{ margin: '12%' }}>
              <IconButton
                type="submit"
                onClick={submitToGetCode}
              >
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
              <IconButton
                onClick={handleEmailCodeClose}
              >
                <CancelIcon htmlColor="red" fontSize="large" />
              </IconButton>
            </div>
          </form>
        </div>
      </Dialog>
    </section>
  );
};

const mapStateToProps = (state) => {
  const { loggingIn, codePayload, user } = state.authentication;
  return { loggingIn, codePayload, user };
};

export default connect(mapStateToProps)(LoginAdminPage);
