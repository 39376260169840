import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useWindowSize from "../Hooks/useWindowSize";
import PrivateSection from "./PrivateSection";
import PublicRoutes from "./PublicRoutes";
import { userService } from "../services/user.service";
import jwt_decode from "jwt-decode";

function Routes() {
  const { pathname } = useLocation();
  const authentication = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);

    const auth =
      authentication === "null" || !authentication ? null : authentication;
    if (!auth) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
    }

    if (authentication && refreshToken) {
      const decodedToken = jwt_decode(authentication);
      const currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        userService
          .refreshToken(authentication, refreshToken)
          .then((response) => {
            debugger;
            if (response) {
              localStorage.setItem("token", response.token);
              localStorage.setItem("refreshToken", response.refreshtoken);
              localStorage.setItem("user", JSON.stringify(response.user));
            }
          });
      }
    }
  }, [authentication, pathname, refreshToken]);

  return authentication ? <PrivateSection /> : <PublicRoutes />;
}

export default Routes;
