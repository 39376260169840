import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DateRangePicker,
  defaultStaticRanges,
  createStaticRanges,
} from "react-date-range";
import { useTheme } from "@material-ui/core/styles";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { Button, CardActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

function DatePicker(props) {
  debugger;
  const theme = useTheme();
  const [ranges, setRanges] = useState([
    {
      startDate: null,
      endDate: null,
      key: "rollup",
    },
  ]);

  const staticRanges = createStaticRanges([
    ...defaultStaticRanges,
    {
      label: "This Year",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate(),
      }),
    },
  ]);

  const appDates = () => {
    debugger;
    props.dataArray([ranges]);
  };

  return (
    <div>
      <DateRangePicker
        startDatePlaceholder="Start Date"
        endDatePlaceholder="End Date"
        rangeColors={[theme.palette.primary.main]}
        ranges={ranges}
        onChange={(ranges) => setRanges([ranges.rollup])}
        staticRanges={staticRanges}
        inputRanges={[]}
      />

      <CardActions display="flex" justifyContent="center">
        <button
          type="submit"
          onClick={appDates}
          className="btn btn-info btn-sm"
        >
          Submit Date
        </button>
      </CardActions>
    </div>
  );
}

export default DatePicker;
