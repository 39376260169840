import React, { useEffect, useState } from "react";
import { TableContainer, Button, TableFooter } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import moment from "moment";
import Table from "@material-ui/core/Table";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DatePickerPopper from "../../Components/DatePickerPopper";
import TriBalanceHeader from "../TableHead/TrialBalanceHarder";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { uploadService } from "../../services/data.service";
import DownloadPage from "../../Components/DownloadPage/DownloadPage";
import { useHistory } from "react-router-dom";
import SLUGS from "../../Resources/slugs";
import BritishCurrency from "../../Components/CurrencyValue";

const start = localStorage.getItem("startDate")
  ? new Date(parseInt(localStorage.getItem("startDate")))
  : null;
const end = localStorage.getItem("endDate")
  ? new Date(parseInt(localStorage.getItem("endDate")))
  : null;

const TrialBalanceTableContent = (props) => {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "15px",
      width: "350px",
      textAlign: "center",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ebebeb",
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const classes = useStyles();

  const startFinancialDate =
    start instanceof Date ? start : new Date(props.startDate);
  const endFinancialDate = end instanceof Date ? end : new Date(props.endDate);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(startFinancialDate);
  const [endDate, setEndDate] = useState(endFinancialDate);
  const [trialBalanceResults, setTrialBalanceREsults] = useState(
    props.uploadData
  );
  const [sumTotal, setSumTotal] = useState(props.sumTotal);
  const [sumUnrestrictedPeriod, setSumUnrestrictedPeriod] = useState(0);
  const [sumUnrestrictedAccumulated, setSumUnrestrictedAccumulated] =
    useState(0);
  const [isdateSelected, setIsDateSelected] = useState(false);
  const [caseDetails, setCaseDetails] = useState([]);

  const handleDatePickerOpen = (event) => {
    setDatePickerOpen(true);
  };
  const changeState = (e) => {
    setStartDate(e[0][0].startDate);
    setEndDate(e[0][0].endDate);

    const startDate = new Date(e[0][0].startDate + 1);
    const endDate = new Date(e[0][0].endDate + 1);
    localStorage.setItem("startDate", startDate.getTime());
    localStorage.setItem("endDate", endDate.getTime());

    uploadService
      .PostTrialBalanceData(
        new Date(e[0][0].startDate),
        new Date(e[0][0].endDate)
      )
      .then((response) => {
        debugger;
        if (response.balance.length > 0) {
          setTrialBalanceREsults(response.balance);
          setSumTotal(+response.sumTotal);
          setSumUnrestrictedPeriod(+response.unrestrictedFundsPeriod);
          setSumUnrestrictedAccumulated(+response.unrestrictedFunds);
        } else {
          setTrialBalanceREsults([]);
          setSumTotal(0);
          setSumUnrestrictedPeriod(0);
          setSumUnrestrictedAccumulated(0);
        }
        setIsDateSelected(true);
      });

    setDatePickerOpen(false);
  };
  const handlePickerClose = (event) => {
    setDatePickerOpen(false);
  };

  const goToAccount = (accountCode, startDate, endDate) => {
    uploadService
      .GetCasesByParam(accountCode, startDate, endDate)
      .then((response) => {
        if (response.length > 0) {
          setCaseDetails(response);
          history.push(SLUGS.casedetaillist, {
            state: { caseDetailList: response, balanceType: "trial" },
          });
        }
      });
  };

  return (
    <>
      <TableContainer>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              style={{
                backgroundColor: "#498f91",
                color: "#323232",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#a7d7d9",
                  fontWeight: "bold",
                  width: "30%",
                  height: "auto",
                }}
                type="button"
                onClick={() => handleDatePickerOpen()}
              >
                {`From ${moment(startDate.toString()).format(
                  "DD/MM/YYYY"
                )} to ${moment(endDate.toString()).format("DD/MM/YYYY")}`}
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      </TableContainer>

      {trialBalanceResults?.length > 0 ? (
        <div style={{ margin: "auto" }} id="trialbalancereport">
          <div
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/images/logo.png")}
              style={{ width: "150px", height: "auto" }}
              alt="logo"
            />
          </div>
          <h5 className="text-center mt-3" style={{ fontWeight: "bold" }}>
            TRIAL BALANCE
          </h5>
          <p className="text-center">
            {`From ${moment(startDate.toString()).format(
              "DD/MM/YYYY"
            )} to ${moment(endDate.toString()).format("DD/MM/YYYY")}`}
          </p>
          <Table>
            <TriBalanceHeader StyledTableCell={StyledTableCell} />
            {trialBalanceResults
              ? trialBalanceResults
                  .sort((a, b) => a.account.localeCompare(b.account))
                  .map((trialDetail, index) =>
                    trialDetail.account === "B01FIXASSE" ? (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          onClick={() =>
                            goToAccount(trialDetail.account, startDate, endDate)
                          }
                        >
                          <a href="#">{trialDetail.account}</a>
                        </StyledTableCell>
                        <StyledTableCell>
                          {trialDetail.accountName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {BritishCurrency(props.sumFixedAssets)}
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                    ) : trialDetail.account.startsWith("B") &&
                      trialDetail.account !== "B01FIXASSE" &&
                      Math.sign(+trialDetail.monetaryAssets) === -1 ? (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          onClick={() =>
                            goToAccount(trialDetail.account, startDate, endDate)
                          }
                        >
                          <a href="#">{trialDetail.account}</a>
                        </StyledTableCell>
                        <StyledTableCell>
                          {trialDetail.accountName}
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>
                          {BritishCurrency(trialDetail.monetaryAssets)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : trialDetail.account.startsWith("B") &&
                      trialDetail.account !== "B01FIXASSE" &&
                      Math.sign(+trialDetail.monetaryAssets) === 1 ? (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          onClick={() =>
                            goToAccount(trialDetail.account, startDate, endDate)
                          }
                        >
                          <a href="#">{trialDetail.account}</a>
                        </StyledTableCell>
                        <StyledTableCell>
                          {trialDetail.accountName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {BritishCurrency(trialDetail.monetaryAssets)}
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      ""
                    )
                  )
              : ""}
            {Math.sign(+props.sumUnrestrictedPeriod) === -1 ? (
              <StyledTableRow>
                <StyledTableCell
                  onClick={() => goToAccount("ENC01", startDate, endDate)}
                >
                  <a href="#">{"ENC01"}</a>
                </StyledTableCell>
                <StyledTableCell>
                  {"Unrestricted Funds for the Period"}
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>
                  {BritishCurrency(
                    sumUnrestrictedPeriod === 0
                      ? props.sumUnrestrictedPeriod
                      : sumUnrestrictedPeriod
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  onClick={() => goToAccount("ENC01", startDate, endDate)}
                >
                  <a href="#">{"ENC01"}</a>
                </StyledTableCell>
                <StyledTableCell>
                  {"Unrestricted Funds for the Period"}
                </StyledTableCell>
                <StyledTableCell>
                  {BritishCurrency(
                    sumUnrestrictedPeriod === 0
                      ? props.sumUnrestrictedPeriod
                      : sumUnrestrictedPeriod
                  )}
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            )}

            {trialBalanceResults
              ? trialBalanceResults.map((trialDetail, index) =>
                  trialDetail.account === "ENC02" &&
                  !trialDetail.totalLibilities &&
                  trialDetail.account !== "B01FIXASSE" &&
                  Math.sign(+trialDetail.monetaryAssets) === -1 ? (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        onClick={() =>
                          goToAccount(trialDetail.account, startDate, endDate)
                        }
                      >
                        <a href="#">{trialDetail.account}</a>
                      </StyledTableCell>
                      <StyledTableCell>
                        {trialDetail.accountName}
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        {BritishCurrency(
                          sumUnrestrictedAccumulated === 0
                            ? props.sumUnrestrictedAccumulated
                            : sumUnrestrictedAccumulated
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : trialDetail.account === "ENC02" &&
                    !trialDetail.totalLibilities &&
                    trialDetail.account !== "B01FIXASSE" &&
                    Math.sign(+trialDetail.monetaryAssets) === 1 ? (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        onClick={() =>
                          goToAccount(trialDetail.account, startDate, endDate)
                        }
                      >
                        <a href="#">{trialDetail.account}</a>
                      </StyledTableCell>
                      <StyledTableCell>
                        {trialDetail.accountName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {BritishCurrency(
                          sumUnrestrictedAccumulated === 0
                            ? props.sumUnrestrictedAccumulated
                            : sumUnrestrictedAccumulated
                        )}
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    ""
                  )
                )
              : ""}

            <TableFooter>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  TOTAL
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  {BritishCurrency(
                    isdateSelected
                      ? sumTotal + props.sumUnrestrictedAccumulated
                      : props.sumTotal + props.sumUnrestrictedAccumulated
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableFooter>
          </Table>
        </div>
      ) : (
        <div style={{ margin: "auto", textAlign: "center", marginTop: 50 }}>
          <h3 className="text-danger">No results found</h3>
        </div>
      )}

      <DownloadPage
        rootElementId={"trialbalancereport"}
        downloadFileName={"trialBalance"}
      />

      <Dialog
        onClose={handlePickerClose}
        open={datePickerOpen}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Search cases by date</DialogTitle>
        <Box className={classes.paper}>
          <DatePickerPopper dataArray={(event) => changeState(event)} />
        </Box>
      </Dialog>
    </>
  );
};

export default TrialBalanceTableContent;
