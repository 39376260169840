import React from "react";
import { slide as Menu } from "react-burger-menu";
import { history } from "../helpers/history";

const Sidebar = () => {
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    history.push("/login");
  };

  const oauth = localStorage.getItem("token");
  return (
    <Menu>
      <a className="menu-item" href="/">
        Home
      </a>
      <a className="menu-item" href="/dashboard">
        Dashboard
      </a>
      {/*    <a className="menu-item" href="/donor">
        Donors
      </a> */}
      <a className="menu-item" href="/donorlist">
        List of Donors
      </a>
      <a className="menu-item" href="/members">
        List of Members
      </a>
      {/*   <a className="menu-item" href="/upload">
        Cases
      </a> */}
      <a className="menu-item" href="/caselist">
        List of Cases
      </a>
      <a className="menu-item" href="/">
        Accountancy
      </a>
      <a className="menu-item" href="/hmrcdonors">
        HMRC Donors
      </a>
      <a className="menu-item" href="/trial">
        Trial Balance
      </a>
      <a className="menu-item" href="/journal">
        Journal
      </a>
      <a className="menu-item" href="/journallist">
        List of Journals
      </a>
      {oauth ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={(event) => logout(event)} className="menu-item" href="">
          Logout
        </a>
      ) : (
        <a className="menu-item" href="/login">
          Login
        </a>
      )}
    </Menu>
  );
};

export default Sidebar;
