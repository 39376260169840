import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../Components/LoadingSpinner";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { uploadService } from "../services/data.service";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import SLUGS from "../Resources/slugs";

const { Label, Control } = { ...Form };

const JournalPage = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [moneyAcc, setMoneyAcc] = useState({});
  const [txtCode, setTxtCode] = useState({});
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    debugger;
    if (success) {
      reset(formData);
    }
    uploadService.getMoneyAccount().then((accountResponse) => {
      if (accountResponse) {
        setMoneyAcc(accountResponse);
      }
    });
    uploadService.getTaxCode().then((account2Response) => {
      if (account2Response) {
        setTxtCode(account2Response);
      }
    });
  }, [formData, reset, success]);

  const handleSubmition = async (data) => {
    debugger;
    const ref = `JOURNAL-${Math.random().toString(36).slice(2, 7)}`;
    setIsLoading(true);
    const formData = {
      Ref: ref,
      TransactionDetails: data.transactionDetails,
      DebitAccount: data.taxCode,
      CreditAccount: data.txtCode,
      Amount: data.amount,
      PaymentDate: data.paymentDate,
    };

    setFormData(formData);

    await uploadService.createJournal(formData).then((response) => {
      if (response) {
        debugger;
        setIsLoading(false);
        setSuccess(true);
        reset(errors);
        reset(register.transactionDetails);
        toast.success("Journal created successfully");
      }
      history.push(SLUGS.journallist);
    });
  };

  const backToJournalList = () => {
    history.push(SLUGS.journallist);
  };

  return (
    <div>
      <>
        <div>
          <div className="pagecontainer">
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              type="text"
              onClick={() => backToJournalList()}
              style={{ float: "left", marginBottom: "2rem" }}
            >
              <ReplyAllIcon
                htmlColor="blue"
                style={{ width: "2rem", height: "2rem", float: "left" }}
              />
            </IconButton>
            <div className="container-fluid p-0">
              <h2 className="text-center">Create Journal</h2>
              {isLoading ? (
                <div className="pluswrap">
                  <div className="plus">
                    <LoadingSpinner />
                  </div>
                </div>
              ) : (
                <Container>
                  <form
                    className="theme-form"
                    onSubmit={handleSubmit(handleSubmition)}
                  >
                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>Transaction Details</Label>
                        <Control
                          className="form-control"
                          type="text"
                          name="transactionDetails"
                          placeholder="Transaction Details"
                          {...register("transactionDetails", {
                            required: true,
                          })}
                        />
                        {errors.transactionDetails && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>Debit Account</Label>
                        <select
                          className="custom-select"
                          {...register("taxCode", {
                            required: true,
                          })}
                        >
                          <option value="">Select Money Account</option>
                          {moneyAcc.length > 0 &&
                            moneyAcc?.map((obj) => {
                              return (
                                <option key={obj.taxCodeID} value={obj.taxCode}>
                                  {obj.taxDescription}
                                </option>
                              );
                            })}
                        </select>
                        {errors.taxCode && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>Credit Account</Label>
                        <select
                          className="custom-select"
                          {...register("txtCode", {
                            required: true,
                          })}
                        >
                          <option value="">Select Tax Code</option>
                          {moneyAcc.length > 0 &&
                            moneyAcc?.map((obj) => {
                              return (
                                <option key={obj.taxCodeID} value={obj.taxCode}>
                                  {obj.taxDescription}
                                </option>
                              );
                            })}
                        </select>
                        {errors.txtCode && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>Amount</Label>
                        <Control
                          className="form-control"
                          type="text"
                          name="amount"
                          placeholder="Amount"
                          {...register("amount", {
                            required: true,
                          })}
                        />
                        {errors.amount && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8 offset-2">
                        <Label>Journal Date</Label>
                        <Control
                          className="form-control"
                          type="date"
                          name="paymentDate"
                          placeholder="Journal Date"
                          {...register("paymentDate", {
                            required: true,
                          })}
                        />
                        {errors.amount && (
                          <span className="text-danger">
                            This field is Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-3 text-center">
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </Container>
              )}
            </div>
          </div>
        </div>
      </>
      <ToastContainer />
    </div>
  );
};

export default JournalPage;
