import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadService } from "../services/data.service";
import { v1 as uuidv1 } from "uuid";
import LoadingSpinner from "../Components/LoadingSpinner";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FcDeleteRow, FcAbout } from "react-icons/fc";
import { history } from "../helpers/history";
import { ConvertToBase64 } from "../Components/ConvertToBase64";
import CurrencyInput from "react-currency-input-field";
import { FinancialYear } from "../Components/FinancialYear";

const { Label, Control } = { ...Form };

const ExpenseUpload = (props) => {
  debugger;
  toast.configure();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [caseDetail, setCaseDetail] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let isMounted = true;

    if (formData.UploadId) {
      uploadService
        .getCaseById(formData.UploadId)
        .then((response) => {
          if (isMounted && response) {
            setCaseDetail(response);
          }
        })
        .catch((error) => {
          toast.error("Failed to fetch case details");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [formData]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const getChange = async (event) => {
    const file = event.target.files;
    setFileDetails(file);
  };

  const handleSubmition = useCallback(
    async (data) => {
      const isValid = FinancialYear(data.PaymentDate);
      if (!isValid) {
        toast.error("Payment Date is not in the current financial year");
        return;
      }
      setIsLoading(true);
      try {
        let base64 = null;
        let base64File = null;
        let fileName = null;

        if (fileDetails && fileDetails.length > 0) {
          base64 = await ConvertToBase64(fileDetails);
          base64File = base64.split(",")[1];
          fileName = fileDetails[0].name;
        }

        const _uploadId = uuidv1();
        const _dateCreated = new Date();
        const ref = `NEW-${Math.random().toString(36).slice(2, 7)}`;

        const formData = {
          UploadId: _uploadId,
          Ref: ref,
          Description: data.description,
          IsPaid: true,
          PaymentDate: data.PaymentDate,
          IsExpenditure: true,
          IsGiftAid: false,
          IsNonGiftAid: false,
          DateCreated: _dateCreated,
          FinancialDate: data.FinancialYearSelected,
          ChurchBranch: data.ChurchBranchSelected,
          Amount: data.Amount,
          DonorsId: "F21930BE-CC0A-4C49-BA38-09E2F4F746E1",
          TxtCodeId: data.TxtCodeSelected,
          CityId: data.ChurchBranchSelected,
          OrgId: "BCC44B30-6AB2-4969-B165-503CFDC9FE14",
          IsSentToHmrc: false,
          TaxCodeID: data.MoneyAccountSelected,
          image: base64File,
          AccountancyCloseId: 19,
          FileName: fileName,
        };

        setFormData(formData);

        const response = await uploadService.uploadCase(formData);
        setIsLoading(false);

        if (response.success) {
          toast.success(response.message);
          setFileDetails(null);
        } else {
          toast.error(response.message);
          setFileDetails(null);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("An error occurred during file upload");
      }
    },
    [fileDetails]
  );

  const goToDetails = (caseDetail) => {
    history.push("/casedetail", { caseDetail });
  };

  const goToDelete = (uploadId) => {};

  return (
    <div>
      {isLoading ? (
        <div className="pluswrap">
          <div className="plus">
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <Container>
          <form className="theme-form" onSubmit={handleSubmit(handleSubmition)}>
            {props.isMobile && (
              <div className="text-center">
                <h6>New Expense Case</h6>
              </div>
            )}
            <div className="row">
              <div className="col-md-6">
                <Label>Money Account</Label>
                <select
                  className="custom-select"
                  {...register("MoneyAccountSelected", { required: true })}
                >
                  <option value="">Select Money Account</option>
                  {props.moneyAcc?.map((obj) => (
                    <option key={obj.taxCodeID} value={obj.taxCodeID}>
                      {obj.taxDescription}
                    </option>
                  ))}
                </select>
                {errors.MoneyAccountSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
              <div className="col-md-6">
                <Label>Description</Label>
                <Control
                  className="form-control"
                  type="text"
                  placeholder="Description"
                  {...register("description", { required: true })}
                />
                {errors.description && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Label>Tax Code</Label>
                <select
                  className="custom-select"
                  {...register("TxtCodeSelected", { required: true })}
                >
                  <option value="">Select Tax Code</option>
                  {props.taxCode
                    .filter((item) => item.isExpensive)
                    .map((obj) => (
                      <option key={obj.txtCodeId} value={obj.txtCodeId}>
                        {obj.txtDescription}
                      </option>
                    ))}
                </select>
                {errors.TxtCodeSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
              <div className="col-md-4">
                <Label>Amount</Label>
                <CurrencyInput
                  className="form-control"
                  inputMode="numeric"
                  placeholder="Amount"
                  {...register("Amount", { required: true })}
                  decimalLimit={2}
                />
                {errors.Amount && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
              <div className="col-md-4">
                <Label>Payment Date</Label>
                <Control
                  className="form-control"
                  type="date"
                  placeholder="Payment Date"
                  {...register("PaymentDate", { required: true })}
                />
                {errors.PaymentDate && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Label>Church Branch</Label>
                <select
                  className="custom-select"
                  {...register("ChurchBranchSelected", { required: true })}
                >
                  <option value="">Select Church Branch</option>
                  {props.branch.map((obj) => (
                    <option key={obj.cityId} value={obj.cityId}>
                      {obj.cityName}
                    </option>
                  ))}
                </select>
                {errors.ChurchBranchSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
              <div className="col-md-4">
                <Label>Upload File</Label>
                <Control
                  className="form-control"
                  type="file"
                  onChange={getChange}
                />
              </div>
              <div className="col-md-4">
                <Label>Financial Year</Label>
                <select
                  className="custom-select"
                  {...register("FinancialYearSelected", { required: true })}
                >
                  {props.financialYear.map((obj) => (
                    <option
                      key={obj.accountancyCloseId}
                      value={obj.accountancyCloseId}
                    >
                      {obj.financialYear}
                    </option>
                  ))}
                </select>
                {errors.FinancialYearSelected && (
                  <span className="text-danger">This field is Required</span>
                )}
              </div>
            </div>
            <div className="form-group mt-3">
              <Button
                variant="primary"
                type="submit"
                disabled={isLoading}
                className="btn-block"
              >
                Submit
              </Button>
            </div>
          </form>
        </Container>
      )}
    </div>
  );
};

export default ExpenseUpload;
