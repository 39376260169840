import { useEffect, useState } from "react";
import moment from "moment";
import { uploadService } from "../services/data.service";

const getYear = new Date().getFullYear();

const OpenCloseFinancialYear = () => {
  const [updateYear, setUpdateYear] = useState(getYear);
  const [unrestrictedFundsPeriod, setUnrestrictedFundsPeriod] = useState(0);

  useEffect(() => {
    getOpenYear();
  }, []);

  const getOpenYear = async () => {
    debugger;
    await uploadService.getYearToOpen().then((res) => {
      debugger;
      if (res) {
        if (res.financialYear) {
          setUpdateYear(res.financialYear);
          setUnrestrictedFundsPeriod(res.unrestrictedPeriod);
        } else {
          setUpdateYear(updateYear);
          setUnrestrictedFundsPeriod(res.activeUnrestricedPeriod);
        }
      }
    });
  };

  const getMonth = new Date().getMonth() + 1;
  const startFinancialYear =
    getMonth > 7 && getMonth < 12 ? getYear : getYear - 1;
  //const startFinancialDate = new Date(`${startFinancialYear}-08-01`);
  const startFinancialDate = new Date("2023-08-01");
  let start = moment(startFinancialDate.toString()).format(
    "YYYY-MM-DD kk:mm:ss"
  );
  const endFinancialYear =
    getMonth > 7 && getMonth <= 12 ? getYear + 1 : getYear;
  //const endFinancialDate = new Date(`${endFinancialYear}-07-31`);
  const endFinancialDate = new Date("2024-07-31");
  let end = moment(endFinancialDate.toString()).format("YYYY-MM-DD kk:mm:ss");

  return {
    start: start,
    end: end,
    unrestrictedFundsPeriod: unrestrictedFundsPeriod,
  };
};

export default OpenCloseFinancialYear;
