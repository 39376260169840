"use strict";

import * as pbi from "powerbi-client";
import { config } from "../helpers";

export class ReportEmbedding {
  constructor() {
    this.pbiService = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
  }

  async embedReport(reportName, hostContainer) {
    try {
      if (!hostContainer) {
        throw new Error("Host container is not defined or accessible.");
      }

      const apiResponse = await this.getReportEmbedModel(reportName);
      const responseContent = await this.getReportEmbedModelFromResponse(
        apiResponse
      );

      const reportConfiguration =
        this.buildReportEmbedConfiguration(responseContent);

      // Log report configuration for debugging
      console.log("Embedding Report Configuration:", reportConfiguration);

      if (!reportConfiguration.embedUrl || !reportConfiguration.accessToken) {
        throw new Error(
          "Invalid report configuration: missing embedUrl or accessToken."
        );
      }

      this.runEmbedding(reportConfiguration, hostContainer, reportName);
    } catch (error) {
      console.error("Error embedding report:", error);
    }
  }

  async getReportEmbedModel(reportName) {
    const url = config.powerBiUrl;
    const token = JSON.parse(localStorage.getItem("token"));

    const request = new Request(`${url}api/powerbiapi/embedding/report`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: JSON.stringify({ reportName }),
    });

    const response = await fetch(request);
    if (!response.ok) {
      throw new Error(`Failed to fetch embed model: ${response.statusText}`);
    }

    return response;
  }

  async getReportEmbedModelFromResponse(response) {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(
        `Error fetching report embed model: ${response.statusText}`
      );
    }
  }

  buildReportEmbedConfiguration(embedModel) {
    if (
      !embedModel ||
      !embedModel.id ||
      !embedModel.embedUrl ||
      !embedModel.accessToken
    ) {
      throw new Error("Invalid embed model received.");
    }

    return {
      id: embedModel.id,
      embedUrl: embedModel.embedUrl,
      accessToken: embedModel.accessToken,
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      permissions: pbi.models.Permissions.Read,
    };
  }

  runEmbedding(reportConfiguration, hostContainer, reportName) {
    try {
      const report = this.pbiService.embed(hostContainer, reportConfiguration);
      report.off("loaded");
      report.on("loaded", () => {
        this.handleTokenExpiration(report, reportName);
      });
    } catch (error) {
      console.error("Error during report embedding:", error);
    }
  }

  handleTokenExpiration(report, reportName) {
    const timeoutMilliseconds = 55 * 60 * 1000;
    setTimeout(async () => {
      try {
        const apiResponse = await this.getReportEmbedModel(reportName);
        const responseContent = await this.getReportEmbedModelFromResponse(
          apiResponse
        );
        this.updateEmbedToken(responseContent, report, reportName);
      } catch (error) {
        console.error("Error handling token expiration:", error);
      }
    }, timeoutMilliseconds);
  }

  async updateEmbedToken(embedModel, report, reportName) {
    try {
      await report.setAccessToken(embedModel.accessToken);
      this.handleTokenExpiration(report, reportName);
    } catch (error) {
      console.error("Error updating embed token:", error);
    }
  }
}
