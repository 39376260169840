import { authHeader, config } from "../helpers";

// Helper Function for Fetching Data
async function fetchData(url, options = {}) {
  try {
    const response = await fetch(url, options);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
}

// Common Request Options Creator
function getRequestOptions(method, body = null) {
  const headers = { "Content-Type": "application/json", ...authHeader() };
  return { method, headers, body: body ? JSON.stringify(body) : null };
}

// Define userService with improved functions
export const userService = {
  login,
  passwordCode,
  logout,
  resetPassword,
  newPassword,
  refreshToken,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
};

function login(email, password) {
  const payload = { email, password };
  const requestOptions = getRequestOptions("POST", payload);

  return fetchData(config.apiUrl + "api/account/login", requestOptions).then(
    (user) => {
      if (user && user.token) {
        // Store user details and jwt token in local storage
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(user.token));
        localStorage.setItem("refreshToken", JSON.stringify(user.refreshToken));
      }
      return user;
    }
  );
}

function passwordCode(startDate, email) {
  const payload = { startDate, email };
  const requestOptions = getRequestOptions("POST", payload);

  return fetchData(
    config.apiUrl + "api/account/requestPasswordCode",
    requestOptions
  ).then((code) => {
    if (code) {
      // Store code details in local storage
      localStorage.setItem("code", JSON.stringify(code.code));
      localStorage.setItem("startDate", JSON.stringify(code.startDate));
    }
    return code;
  });
}

function resetPassword(newPassword, email) {
  const payload = { NewPassword: newPassword, Email: email };
  const requestOptions = getRequestOptions("POST", payload);

  return fetchData(config.apiUrl + "api/account/resetPassword", requestOptions);
}

function newPassword(newPassword) {
  const username = JSON.parse(localStorage.getItem("username"));
  const payload = { Username: username, NewPassword: newPassword };
  const requestOptions = getRequestOptions("POST", payload);

  return fetchData(
    config.apiUrl + "api/account/resetPassword",
    requestOptions
  ).then((user) => {
    if (user && user.token) {
      // Store user details and jwt token in local storage
      localStorage.setItem("user", JSON.stringify(user));
    }
    return user;
  });
}

function logout() {
  // Remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

function getAll() {
  const requestOptions = getRequestOptions("GET");

  return fetchData(config.apiUrl + "/users", requestOptions);
}

function getById(id) {
  const requestOptions = getRequestOptions("GET");

  return fetchData(config.apiUrl + "/users/" + id, requestOptions);
}

function register(user) {
  const requestOptions = getRequestOptions("POST", user);

  return fetchData(
    config.proxyUrl + (config.apiUrl + "api/account/register"),
    requestOptions
  );
}

function refreshToken(token, refreshToken) {
  const payload = { Token: token, RefreshToken: refreshToken };
  const requestOptions = getRequestOptions("POST", payload);

  return fetchData(config.apiUrl + "api/account/refreshtoken", requestOptions);
}

function update(user) {
  const requestOptions = getRequestOptions("PUT", user);

  return fetchData(config.apiUrl + "/users/" + user.id, requestOptions);
}

// Prefixed function name with underscore because delete is a reserved word in JavaScript
function _delete(id) {
  const requestOptions = getRequestOptions("DELETE");

  return fetchData(config.apiUrl + "/users/" + id, requestOptions);
}

// Handle Response
function handleResponse(response) {
  return response.ok
    ? response.json().catch(() => {}) // Handle non-JSON responses
    : Promise.reject(response.text().catch(() => "An error occurred"));
}

// Handle Error
function handleError(error) {
  return Promise.reject(error.message || "An unknown error occurred.");
}
