import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SLUGS from "../Resources/slugs";
import LoadingSpinner from "../Components/LoadingSpinner";
import LoginAdminPage from "../pages/LoginAdminPage";
import PrivateRoutes from "../routes/PrivateRoutes";

// Lazy load components
const DashboardComponent = lazy(() => import("../pages/ReportDashboard"));

function PublicRoutes() {
  return (
    <Suspense fallback={<LoadingSpinner loading />}>
      <Switch>
        <Route path={SLUGS.login} component={LoginAdminPage} />
        <Route path={SLUGS.forgotPassword} component={LoginAdminPage} />
        <PrivateRoutes path={SLUGS.dashboard} component={DashboardComponent} />
        <Redirect to={SLUGS.login} />
      </Switch>
    </Suspense>
  );
}

export default PublicRoutes;
