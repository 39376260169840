import { generalConstants } from "../constants";
import { uploadService } from "../services/data.service";
import { alertActions } from "./";
import { history } from "../helpers/history";

export const donorActions = {
  createtDonor,
  updateDonor,
  getAll,
  delete: _delete,
};

function createtDonor(payload) {
  debugger;
  return (dispatch) => {
    dispatch(request({ payload }));

    uploadService.createDonor(payload).then(
      (payload) => {
        dispatch(success(payload));
        history.push("/donor");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(payload) {
    return { type: generalConstants.DONOR_REQUEST, payload };
  }
  function success(payload) {
    return { type: generalConstants.DONOR_SUCCESS, payload };
  }
  function failure(error) {
    return { type: generalConstants.DONOR_FAILURE, error };
  }
}

function updateDonor(payload) {
  return (dispatch) => {
    dispatch(request({ payload }));

    uploadService.updateDonor(payload).then(
      (payload) => {
        dispatch(success(payload));
        history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(payload) {
    return { type: generalConstants.DONOR_UPDATE_REQUEST, payload };
  }
  function success(payload) {
    return { type: generalConstants.DONOR_UPDATE_SUCCESS, payload };
  }
  function failure(error) {
    return { type: generalConstants.DONOR_UPDATE_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    uploadService.getAllDonors().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: generalConstants.DONOR_ALL_REQUEST };
  }
  function success(users) {
    return { type: generalConstants.DONOR_ALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: generalConstants.DONOR_ALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    uploadService.delete(id).then(
      () => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: generalConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: generalConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: generalConstants.DELETE_FAILURE, id, error };
  }
}
