import React, { useState, useEffect, useCallback } from "react";
import DonorsTableContent from "../Components/TableContent/DonorsTableContent";
import { uploadService } from "../services/data.service";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import LoadingSpinner from "../Components/LoadingSpinner";

const DonorsList = () => {
  const [donors, setDonors] = useState([]);

  const listDonors = useCallback(() => {
    debugger;
    const getDonorList = async () => {
      await uploadService.getAllDonors().then((response) => {
        setDonors(response);
      });
    };
    getDonorList();
  }, []);

  useEffect(() => {
    const AllData = setTimeout(() => {
      listDonors();
    }, 500);

    return () => clearTimeout(AllData);
  }, [listDonors, donors]);

  return (
    <div>
      <>
        {donors.length > 0 ? (
          <div>
            <div className="pagecontainer">
              <div className="container-fluid p-0">
                <h2>List of Donors</h2>
                <Container>
                  <DonorsTableContent donors={donors} />
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ TextAlign: "center" }}>
            <LoadingSpinner
              style={{
                flex: 1,
                MarginTop: 240,
                JustifyContent: "center",
                AlignItems: "center",
              }}
            />
          </div>
        )}
      </>
      <ToastContainer />
    </div>
  );
};

export default DonorsList;
