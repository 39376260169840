import React, { useEffect, useState, useCallback } from "react";
import Table from "@material-ui/core/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton, TableContainer, Grid } from "@material-ui/core";
import { uploadService } from "../../services/data.service";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import { SearchGiftAid } from "../Utility";
import { Button } from "@material-ui/core";
import moment from "moment";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DatePickerPopper from "../DatePickerPopper";
import { ExportToExcel } from "../../Components/ExportToExcell";
import { GetFinancialYear } from "../../Components/FinancialYear";

//Modal
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CancelIcon from "@material-ui/icons/Cancel";
import PublishIcon from "@material-ui/icons/Publish";
import { toast } from "react-toastify";
import HmrcDonorsTableHeader from "../TableHead/HmrcDonorsTableHeadder";
import LoadingButton from "../LoadingValue";
import { useHistory } from "react-router-dom";
import SLUGS from "../../Resources/slugs";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortedRowInformation = (rowArray, comparator) => {
  const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedRowArray.map((el) => el[0]);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ebebeb",
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px",
    width: "350px",
    textAlign: "center",
  },
}));

export default function HmrcTableContent(props) {
  const fileName = "HMRC_List";
  const history = useHistory();
  const startFinancialDate = new Date(GetFinancialYear().start);
  const endFinancialDate = new Date(GetFinancialYear().end);

  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hmrcOpen, setHmrcOpen] = useState(false);

  const classes = useStyles();
  const [cases, setCases] = useState(props.cases);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(startFinancialDate);
  const [endDate, setEndDate] = useState(endFinancialDate);
  const [showLoader, setShowLoader] = useState(false);
  const [totalPeriod, setTotalPeriod] = useState(0);

  const _totalPeriod = useCallback(() => {
    setTotalPeriod(
      cases.reduce(
        (total, currentValue) => (total = total + currentValue.amount),
        0
      )
    );
  }, [cases]);

  useEffect(() => {
    const hmrcData = setTimeout(() => {
      _totalPeriod();
    }, 1000);

    return () => clearTimeout(hmrcData);
  }, [_totalPeriod]);

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0);
  };

  const handleHmrcClose = () => {
    setHmrcOpen(false);
  };

  const handleHmrcOpen = (e) => {
    e.preventDefault();
    setHmrcOpen(true);
  };

  const handleDatePickerOpen = (event) => {
    debugger;
    setDatePickerOpen(true);
  };

  const changeState = (e) => {
    debugger;
    setStartDate(e[0][0].startDate);
    setEndDate(e[0][0].endDate);
    localStorage.setItem("startDate", new Date(e[0][0].startDate).getTime());
    localStorage.setItem("endDate", new Date(e[0][0].endDate).getTime());
    const dataResult = SearchGiftAid(
      new Date(e[0][0].startDate),
      new Date(e[0][0].endDate),
      props.cases
    );
    setDatePickerOpen(false);
    setCases(dataResult);
  };

  const handlePickerClose = (event) => {
    setDatePickerOpen(false);
  };

  const sendtoHmrc = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    await uploadService
      .PostGiftAidToHmrc(cases, startDate, endDate)
      .then((response) => {
        if (response.successMessage) {
          toast.success(response.successMessage);
          handleHmrcClose();
          history.push(SLUGS.caselist);
        } else {
          toast.error(response.errorMessage);
          handleHmrcClose();
          history.push(SLUGS.caselist);
        }
        setShowLoader(false);
      });
  };

  return (
    <>
      <TableContainer>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              style={{
                backgroundColor: "#498f91",
                color: "#323232",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#a7d7d9",
                  fontWeight: "bold",
                  width: "30%",
                  height: "auto",
                }}
                type="button"
                onClick={() => handleDatePickerOpen()}
              >
                {`From ${moment(startDate.toString()).format(
                  "DD/MM/YYYY"
                )} to ${moment(endDate.toString()).format("DD/MM/YYYY")}`}
              </Button>
            </Toolbar>
          </AppBar>
        </Box>

        <Table className="mt-3">
          <HmrcDonorsTableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={handleRequestSort}
            StyledTableCell={StyledTableCell}
          />
          {sortedRowInformation(
            cases,
            getComparator(orderDirection, valueToOrderBy)
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((caseDetail, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{caseDetail.firstname}</StyledTableCell>
                <StyledTableCell>{caseDetail.lastname}</StyledTableCell>
                <StyledTableCell>{caseDetail.houseNo}</StyledTableCell>
                <StyledTableCell>{caseDetail.postcode}</StyledTableCell>
                <StyledTableCell>
                  {caseDetail.amount.toFixed(2)}
                </StyledTableCell>
                <StyledTableCell>
                  {moment(caseDetail.paymentDate).format("DD/MM/YYYY")}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </Table>
      </TableContainer>

      <div>
        <p style={{ fontWeight: "bold" }}>
          Total Period: £ {cases.length > 0 ? totalPeriod.toFixed(2) : 0.0}
        </p>
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}>
          Total Gift Aid Period: £{" "}
          {cases.length > 0 ? (totalPeriod * 0.25).toFixed(2) : 0.0}
        </p>
      </div>
      <div className="row">
        <div>
          <LoadingButton
            text="Send to HMRC"
            onSubmit={(e) => handleHmrcOpen(e)}
            loading={showLoader}
            disabled={showLoader || cases.length === 0}
          />
        </div>
        <div style={{ marginLeft: "5px" }}>
          <ExportToExcel apiData={cases} fileName={fileName} />
        </div>
      </div>

      <TablePagination
        rowsPerPageOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        component="div"
        count={cases.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Dialog
        onClose={handlePickerClose}
        open={datePickerOpen}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Search cases by date</DialogTitle>
        <Box className={classes.paper}>
          <DatePickerPopper dataArray={(event) => changeState(event)} />
        </Box>
      </Dialog>

      <Dialog onClose={handleHmrcClose} open={hmrcOpen}>
        <DialogTitle>Do you really want to send this Gift Aid now?</DialogTitle>
        <Box className="dialogForm">
          <form id="myForm">
            <IconButton
              color="blue"
              type="submit"
              onClick={(e) => sendtoHmrc(e)}
            >
              <PublishIcon htmlColor="blue" fontSize="large" />
            </IconButton>
            <IconButton
              color="red"
              type="button"
              onClick={() => handleHmrcClose()}
            >
              <CancelIcon htmlColor="red" fontSize="large" />
            </IconButton>
          </form>
        </Box>
      </Dialog>
    </>
  );
}
