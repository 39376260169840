export const generalConstants = {
  DONOR_REQUEST: "DONOR_REQUEST",
  DONOR_SUCCESS: "DONOR_SUCCESS",
  DONOR_FAILURE: "DONOR_FAILURE",

  DONOR_UPDATE_REQUEST: "DONOR_UPDATE_REQUEST",
  DONOR_UPDATE_SUCCESS: "DONOR_UPDATE_SUCCESS",
  DONOR_UPDATE_FAILURE: "DONOR_UPDATE_FAILURE",

  DONOR_ALL_REQUEST: "DONOR_ALL_REQUEST",
  DONOR_ALL_SUCCESS: "DONOR_ALL_SUCCESS",
  DONOR_ALL_FAILURE: "DONOR_ALL_FAILURE",

  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_FAILURE: "DELETE_FAILURE",
};
