export default {
  // private
  homepage: "/",
  dashboardHome: "/dashboardhome",
  dashboard: "/dashboard",
  donorDetails: "/donorDetails",
  donor: "/donorDetails/donor",
  donors: "/donorDetails/donors",
  donorlist: "/donorDetails/donorlist",
  caseDetails: "/caseDetails",
  caselist: "/caseDetails/caselist",
  casedetail: "/caseDetails/casedetail",
  upload: "/caseDetails/upload",
  gaUpload: "/caseDetails/upload/ga",
  memberDetails: "/memberDetails",
  members: "/memberDetails/members",
  createMembers: "/memberDetails/createMembers",
  accountancy: "/accountancy",
  trial: "/accountancy/trial",
  balanceSheet: "/accountancy/balance",
  incexpreport: "/accountancy/income_exp",
  journal: "/accountancy/journal",
  journallist: "/accountancy/journallist",
  casedetaillist: "/accountancy/casedetaillist",
  openclose: "/accountancy/openclose",
  test: "/accountancy/test",
  hmrc: "/hmrc",
  hmrcdonors: "/hmrc/hmrcdonors",
  editJournalCase: "/editJournalCase",
  // auth
  login: "/login",
  forgotPassword: "/forgot_password",
};
