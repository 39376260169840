import React, { useState, useEffect } from "react";
import logo from "../assets/images/church-logo.png";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router";
//import app from "../data/base";
import { userService } from "../services/user.service";
import LoadingSpinner from "../Components/LoadingSpinner";

const LoginPage = ({ history }) => {
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    localStorage.setItem("profileURL", value);
  }, [value]);

  const login = async () => {
    setIsAuth(true);
    try {
      await userService.login(username, password).then((response) => {
        if (response.token) {
          setIsAuth(false);
          localStorage.setItem("token", response.token);
          localStorage.setItem("refreshToken", response.refreshToken);
          setValue(man);
          history.push(`${process.env.PUBLIC_URL}/`);
        }
      });
    } catch (error) {
      setIsAuth(false);
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  const resetPassword = async (username) => {
    try {
      await userService.resetPassword(username).then((response) => {
        debugger;
        if (response.successMessage) {
          localStorage.setItem("username", username);
          localStorage.setItem("dateEmailCreated", response.dateEmailCreated);
          toast.success(
            "Email sent successfully! Please check your email to reset your password"
          );
        } else {
          toast.error(`${response.errorMessage}`);
        }
      });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The email address is mandatory for password reset."
        );
      }, 200);
    }
  };

  return (
    <div
      col-sm-8
      col-offset-sm-2
      col-md-6
      col-offset-md-4
      col-lg-6
      col-offset-lg-4
    >
      {isAuth ? (
        <LoadingSpinner />
      ) : (
        <div className="page-wrapper form_login">
          <div className="container-fluid p-0">
            {/* <!-- login page start--> */}
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        <img src={logo} alt="" />
                      </div>
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="text-center">
                            <h4>LOGIN</h4>
                            <h6>Enter your Username and Password </h6>
                          </div>
                          <form className="theme-form">
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                Your Name
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={username}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email address"
                              />
                              {/* {errors.email && 'Email is required'} */}
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">Password</label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              {/* {errors.password && 'Email is required'} */}
                            </div>
                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" />
                              <label htmlFor="checkbox1">Remember me</label>
                            </div>
                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={() => login()}
                              >
                                Login
                              </button>
                            </div>
                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-info btn-block"
                                type="button"
                                onClick={() => resetPassword(username)}
                              >
                                Reset Your Password
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
            {/* <!-- login page end--> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(LoginPage);
