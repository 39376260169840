import React, { useEffect, useState } from "react";
import { createEntry } from "../Components/Utility";
import SearchIcon from "@material-ui/icons/Search";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { useLocation } from "react-router";
import moment from "moment";

//Modal
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import { toast } from "react-toastify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { uploadService } from "../services/data.service";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SLUGS from "../Resources/slugs";

const CaseDetail = () => {
  debugger;
  const history = useHistory();
  const { state } = useLocation();
  let caseDetail = null;
  let moneyAccountList = [];
  let txtCodeList = [];

  if (state?.state.caseDetails) {
    caseDetail = state?.state.caseDetails;
    localStorage.setItem("caseDetailData", JSON.stringify(caseDetail));
  }
  if (state?.state.moneyAcc) {
    moneyAccountList = state?.state.moneyAcc;
    localStorage.setItem(
      "moneyAccountUpdate",
      JSON.stringify(moneyAccountList)
    );
  }
  if (state?.state.txtCode) {
    txtCodeList = state?.state.txtCode;
    localStorage.setItem("taxCodeAccountUpdate", JSON.stringify(txtCodeList));
  }
  if (localStorage.getItem("caseDetailData")) {
    caseDetail = JSON.parse(localStorage.getItem("caseDetailData"));
  }
  if (localStorage.getItem("moneyAccountUpdate")) {
    moneyAccountList = JSON.parse(localStorage.getItem("moneyAccountUpdate"));
  }
  if (localStorage.getItem("taxCodeAccountUpdate")) {
    txtCodeList = JSON.parse(localStorage.getItem("taxCodeAccountUpdate"));
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "15px",
      width: "350px",
      textAlign: "center",
    },
    uploadImg: {
      position: "absolute",
      top: "-35px",
      left: 0,
      height: "calc(100% + 36px)",
      width: "calc(100% + 5px)",
      outline: "none",
    },
  }));

  const listAccountTypes = [
    { id: 1, value: "ga", content: "Gift Aid" },
    { id: 2, value: "exp", content: "Expenditure" },
    { id: 3, value: "nonga", content: "Non GiftAid" },
  ];

  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [caseDetails, setCaseDetails] = useState({});
  const [updateId, setUpdateId] = useState("");

  //Edit Form
  const [caseRef, setRef] = useState(caseDetails.ref);
  const [description, setDescription] = useState(caseDetails.description);
  const [churchBranch, setChurchBranch] = useState(caseDetails.churchBranch);
  const [paymentDate, setPaymentDate] = useState(null);
  const [base64Img, setbase64Img] = useState("");
  const classes = useStyles();
  const [amount, setAmount] = useState(caseDetails.amount);
  const [caseType, setCaseType] = useState();
  const [donors, setDonors] = useState([]);
  const [donor, setDonor] = useState("0");
  const [displayImg, setDisplayImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [FileName, setFileName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const handleClose = () => {
    setOpen(false);
  };

  const goToEditPage = (caseDetail, moneyAcc, txtCode) => {
    history.push(SLUGS.editJournalCase, {
      state: { caseDetails: caseDetail, moneyAcc: moneyAcc, txtCode: txtCode },
    });
  };

  const handleOpen = (event, Id, caseDetails) => {
    debugger;
    setIsLoading(true);
    uploadService.getCaseById(Id).then((response) => {
      debugger;
      if (response) {
        setCaseDetails(response);
        setDisplayImg(response.imgBase64);
        setCaseType(
          response.isGiftAid === true
            ? "ga"
            : response.isNonGiftAid === true
            ? "nonga"
            : "exp"
        );

        setIsLoading(false);
      }
    });
    setUpdateId(Id);
    setOpen(true);
    setDeleteOpen(false);
    uploadService.getAllDonors().then((response) => {
      if (response) {
        setDonors(response);
        setDonor(caseDetails.donorsId);
      }
    });
  };

  const handleChange = (event) => {
    setCaseType(event.target.value);
  };
  const handleDonorChange = (event) => {
    setDonor(event.target.value);
  };
  const handlePaymentDateChange = (event) => {
    setPaymentDate(event);
  };

  const convertTobase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file[0]);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getFileDetails = (event) => {
    debugger;
    const img = event.target.files;
    convertTobase64(img).then((result) => {
      const base64File = result.split(",")[1];
      setbase64Img(base64File);
      setFileName(img[0].name);
      setDisplayImg(`data:image/${img[0].name};base64,${base64File}`);
    });
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    let newImage = "";
    if (!base64Img) {
      setFileName(caseDetails.fileName);
      newImage = displayImg.split(",")[1];
    } else {
      setFileName(caseDetails.ref);
      newImage = base64Img;
    }
    const _exp = caseType === "exp" ? true : false;
    const _Ga = caseType === "ga" ? true : false;
    const _nonGa = caseType === "nonga" ? true : false;

    const formData = {
      UploadId: caseDetails.uploadId,
      Ref: caseRef ? caseRef : caseDetails.ref,
      Description: description ? description : caseDetails.description,
      IsPaid: caseDetails.isPaid,
      PaymentDate: !paymentDate ? caseDetails.paymentDate : paymentDate,
      IsExpenditure: _exp,
      IsGiftAid: _Ga,
      IsNonGiftAid: _nonGa,
      CityId: churchBranch ? churchBranch : caseDetails.cityId,
      DonorsId:
        donor.length > 0
          ? donor
          : caseDetails.donorsId.length > 0
          ? caseDetails.donorsId
          : "f21930be-cc0a-4c49-ba38-09e2f4f746e1",
      TaxCodeID: caseDetails.taxCodeID,
      TxtCodeId: caseDetails.txtCodeId,
      FinancialDate: caseDetails.financialDate,
      DateCreated: caseDetails.dateCreated,
      ChurchBranch: churchBranch ? churchBranch : caseDetails.churchBranch,
      Amount: amount ? `${amount}` : `${caseDetails.amount}`,
      OrgId:
        caseDetails.orgId.length > 0
          ? caseDetails.orgId
          : "BCC44B30-6AB2-4969-B165-503CFDC9FE14",
      IsSentToHmrc: caseDetails.isSentToHmrc,
      image: newImage,
      AccountancyClosedId: `${22}`,
      FileName: FileName ? FileName : `${caseDetails.ref}.png`,
    };
    debugger;
    await uploadService
      .updateCase(formData)
      .then((response) => {
        if (response) {
          const fileExtension = response.fileName.split(".").pop();
          response.imgBase64 = `data:image/${fileExtension};base64,${response.imgBase64}`;
          setCaseDetails(response);
          setUpdateId(updateId);
          setOpen(false);
          setIsProcessing(false);
          toast.success("Case updated sucessfully!");
          history.push(SLUGS.caselist);
        }
      })
      .catch((error) => {
        toast.error(`Error whilst updating case: ${error}`);
        history.push(SLUGS.caselist);
      });
  };

  const goToPage = () => {
    history.push(SLUGS.caselist);
  };

  const downloadDoc = async (uploadId) => {
    await uploadService.downloadCaseById(uploadId).then((response) => {
      if (response.base64) {
        const linkSource = `${response.base64}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = response.fileName;
        downloadLink.click();
      }
    });
  };

  const renderCaseOverview = () => {
    return (
      <Grid item xs={12}>
        <Card
          style={{
            //minWidth: 300
            flex: 1,
          }}
        >
          <CardContent style={{ float: "left", width: "100%" }}>
            <Typography variant="h5" style={{ marginBottom: 15 }}>
              Case Overview <SearchIcon />
            </Typography>
            <Grid container spacing={3} style={{ display: "flex", flex: 1 }}>
              <Grid item xs={4}>
                {createEntry(
                  "Case Reference",
                  caseDetail ? caseDetail.ref : caseDetails.ref
                )}
                {createEntry(
                  "Case Description",
                  caseDetail ? caseDetail.description : caseDetails.description
                )}
                {createEntry(
                  "Church Branch",
                  caseDetail ? caseDetail.cityName : caseDetails.cityName
                )}
                {caseDetail && caseDetail.isGiftAid === true
                  ? createEntry("Full Name", caseDetail.fullname)
                  : caseDetails && caseDetails.isGiftAid === true
                  ? createEntry("Full Name", caseDetails.fullname)
                  : ""}
              </Grid>
              <Grid item xs={4}>
                {createEntry(
                  "Case Type",
                  caseDetail && caseDetail.isGiftAid === true
                    ? "Gift Aid"
                    : caseDetails && caseDetails.isGiftAid === true
                    ? "Gift Aid"
                    : caseDetail && caseDetail.isExpenditure === true
                    ? "Expense"
                    : caseDetails && caseDetails.isExpenditure === true
                    ? "Expense"
                    : "Non-Gift Aid"
                )}
                {createEntry(
                  "Payment Date",
                  moment(
                    caseDetail
                      ? caseDetail.paymentDate
                      : caseDetails.paymentDate
                  ).format("DD/MM/YYYY")
                )}
                {createEntry(
                  "Church Branch",
                  caseDetail ? caseDetail.cityName : caseDetails.cityName
                )}
                {caseDetail && caseDetail.isGiftAid === true
                  ? createEntry(
                      "Address",
                      `${caseDetail.housenumber}, ${caseDetail.postcode}`
                    )
                  : caseDetails && caseDetails.isGiftAid === true
                  ? createEntry(
                      "Address",
                      `${caseDetails.housenumber}, ${caseDetails.postcode}`
                    )
                  : ""}
              </Grid>
              <Grid item xs={4}>
                {createEntry(
                  "Amount Paid",
                  formatter.format(
                    caseDetail ? caseDetail.amount : caseDetails.amount
                  )
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card
          style={{
            //minWidth: 300
            flex: 1,
            marginTop: 20,
          }}
        >
          <CardContent style={{ float: "left", width: "100%" }}>
            <Typography variant="h5" style={{ marginBottom: 15 }}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    endIcon={<SendIcon />}
                    style={{ marginRight: "30%" }}
                    onClick={() => goToPage()}
                  >
                    Back to Case List
                  </Button>
                  Case Details
                  <SearchIcon />
                </Grid>
              </Grid>
            </Typography>
            <Grid container spacing={3} style={{ display: "flex", flex: 1 }}>
              <Grid item xs={12}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Case Ref</th>
                      <th>Description</th>
                      <th>Branch</th>
                      <th>Case Type</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{caseDetail ? caseDetail.ref : caseDetails.ref}</td>
                      <td>
                        {caseDetail
                          ? caseDetail.description
                          : caseDetails.description}
                      </td>
                      <td>
                        {caseDetail
                          ? caseDetail.cityName
                          : caseDetails.cityName}
                      </td>
                      <td>
                        {caseDetail && caseDetail.isGiftAid === true
                          ? "Gift Aid"
                          : caseDetails && caseDetails.isGiftAid === true
                          ? "Gift Aid"
                          : caseDetail && caseDetail.isExpenditure === true
                          ? "Expense"
                          : caseDetails && caseDetails.isExpenditure === true
                          ? "Expense"
                          : "Non-Gift Aid"}
                      </td>
                      <td>
                        {formatter.format(
                          caseDetail ? caseDetail.amount : caseDetails.amount
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-success"
                          /* onClick={(event) =>
                            handleOpen(
                              event,
                              caseDetail
                                ? caseDetail.uploadId
                                : caseDetails.uploadId,
                              caseDetail ? caseDetail : caseDetails,
                              moneyAccountList,
                              txtCodeList
                            ) */
                          onClick={() =>
                            goToEditPage(
                              caseDetail ? caseDetail : caseDetails,
                              moneyAccountList,
                              txtCodeList
                            )
                          }
                          style={{ marginRight: 7 }}
                        >
                          Edit Case
                        </button>
                        <button
                          className="btn btn-info"
                          onClick={() =>
                            downloadDoc(
                              caseDetail
                                ? caseDetail.uploadId
                                : caseDetails.uploadId
                            )
                          }
                        >
                          Download Doc
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <div style={{ maxWidth: "100vw", marginTop: "1%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              {renderCaseOverview()}
            </Grid>
          </Grid>
        </Grid>

        {isLoading ? (
          <p>...Loading</p>
        ) : (
          <Dialog onClose={handleClose} open={open} disableEnforceFocus>
            <DialogTitle>Edit Case</DialogTitle>
            <Box className={classes.paper}>
              <form id="myForm">
                <div>
                  <TextField
                    label="Reference"
                    name="ref"
                    hintText="Reference"
                    defaultValue={caseDetails.ref}
                    onChange={(e) => setRef(e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    label="Details"
                    name="description"
                    hintText="Details"
                    defaultValue={caseDetails.description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    label="Church"
                    name="churchBranch"
                    hintText="Church"
                    defaultValue={caseDetails.churchBranch}
                    onChange={(e) => setChurchBranch(e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    label="Amount"
                    name="amount"
                    hintText="Amount"
                    defaultValue={caseDetails.amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <div>
                  <Select
                    labelId="caseType"
                    id="caseTypeId"
                    value={caseType}
                    label="Case Type"
                    onChange={handleChange}
                    style={{
                      width: "50%",
                      height: "20%",
                      marginLeft: "-12%",
                      marginTop: 4,
                    }}
                  >
                    {listAccountTypes?.map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.content}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {caseDetails.isGiftAid ? (
                  <div>
                    <Select
                      labelId="donorsId"
                      id="donorsId"
                      value={donor === "0" ? "Select Donor" : donor}
                      label="Donors"
                      onChange={handleDonorChange}
                      style={{
                        width: "50%",
                        height: "20%",
                        marginLeft: "-12%",
                        marginTop: 4,
                      }}
                    >
                      {donors?.map((item) => (
                        <MenuItem key={item.donorsId} value={item.donorsId}>
                          {item.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date desktop"
                      inputFormat="dd/MM/yyyy"
                      value={
                        !paymentDate ? caseDetails.paymentDate : paymentDate
                      }
                      onChange={handlePaymentDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <div style={{ marginTop: 5, marginBottom: 5 }}>
                    {/*  <img
                      src={displayImg ? displayImg : caseDetails.imgBase64}
                      alt="caseImage"
                      style={{ width: "30%", height: "30%" }}
                    /> */}
                    <label style={{ color: "blue" }}>
                      File Name:{" "}
                      {FileName.length > 0 ? FileName : caseDetails.fileName}
                    </label>
                  </div>
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={getFileDetails}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload New File
                    </Button>
                  </label>
                </div>
                <div>
                  <IconButton
                    type="submit"
                    onClick={(event) => handleEdit(event)}
                  >
                    <AddCircleIcon color="primary" fontSize="large" />
                  </IconButton>
                  <IconButton type="button" onClick={handleClose}>
                    <CancelIcon htmlColor="red" fontSize="large" />
                  </IconButton>
                </div>
                <div className="text-center">
                  {isProcessing ? (
                    <p style={{ fontWeight: "bold" }}>Please wait...</p>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </Box>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default CaseDetail;
