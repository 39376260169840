import React, { useState, useEffect, useCallback } from "react";
import HmrcTableContent from "../Components/TableContent/HmrcTableContent";
import { uploadService } from "../services/data.service";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import OpenCloseFinancialYear from "../Components/OpenCloseYear";

const GiftAidDonorsList = () => {
  const startFinancialDate = new Date(OpenCloseFinancialYear().start);
  const endFinancialDate = new Date(OpenCloseFinancialYear().end);
  const [cases, setCases] = useState([]);

  const listHmrcDonors = useCallback(() => {
    const getData = async () => {
      const date1 = startFinancialDate;
      const date2 = endFinancialDate;
      await uploadService
        .getAllGiftAidCases(date1, date2)
        .then((response) => {
          setCases(response.listData);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getData();
  }, [endFinancialDate, startFinancialDate]);

  useEffect(() => {
    const AllData = setTimeout(() => {
      listHmrcDonors();
    }, 500);

    return () => clearTimeout(AllData);
  }, [listHmrcDonors]);

  return (
    <div>
      <>
        {cases.length > 0 ? (
          <div>
            <div className="pagecontainer">
              <div className="container-fluid p-0">
                <h2 className="text-center">List of HMRC Cases</h2>
                <Container>
                  <HmrcTableContent cases={cases} />
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-danger">There is not any Gift Aid to HMRC</p>
          </div>
        )}
      </>
      <ToastContainer />
    </div>
  );
};

export default GiftAidDonorsList;
