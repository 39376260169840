import React, { useEffect } from "react";
import { Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "./helpers";
import { alertActions } from "./actions";
import { PrivateRoute } from "./PrivateRoute";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/Login";
import CaseUpload from "./pages/CaseUpload";
import DonorsListPage from "./pages/DonorsListPage";
import DonorsList from "./pages/ListDonors";
import CaseDetail from "./pages/CaseDetailPage";
import CaseList from "./pages/ListCases";
import AllMembers from "./pages/ChurchMembers";
import DonorsPage from "./pages/DonorsPage";
import GiftAidDonorsList from "./pages/HmrcList";
import ResetPasswordPage from "./pages/ResetPassword";
import Test from "./pages/test";
import Sidebar from "./pages/Sidebar";
import ReportDashboard from "./pages/ReportDashboard";
import TrialBalance from "./pages/TrialBalance";
import JournalPage from "./pages/JournalPage";
import JournalList from "./pages/JournalList";
import jwt_decode from "jwt-decode";
import { userService } from "./services/user.service";

const App = ({ alert, dispatch }) => {
  useEffect(() => {
    // Clear alert on location change
    const unlisten = history.listen(() => {
      dispatch(alertActions.clear());
    });

    return () => unlisten();
  }, [dispatch]);

  const authentication = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  useEffect(() => {
    const checkAuthToken = () => {
      if (!authentication) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        return;
      }

      const decodedToken = jwt_decode(authentication);
      const currentDate = new Date();

      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        userService
          .refreshToken(authentication, refreshToken)
          .then((response) => {
            if (response) {
              localStorage.setItem("token", response.token);
              localStorage.setItem("refreshToken", response.refreshtoken);
              localStorage.setItem("user", JSON.stringify(response.user));
            }
          });
      }
    };

    checkAuthToken();
  }, [authentication, refreshToken]);

  return (
    <div id="page-wrap">
      {authentication && <Sidebar />}
      <div className="container">
        {alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )}
        <Router history={history}>
          {!authentication && <Route path="/login" component={LoginPage} />}
          <Route path="/resetPassword" component={ResetPasswordPage} />
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute path="/upload" component={CaseUpload} />
          <PrivateRoute path="/dashboard" component={ReportDashboard} />
          <PrivateRoute path="/casedetail" component={CaseDetail} />
          <PrivateRoute path="/donor" component={DonorsPage} />
          <PrivateRoute path="/donors" component={DonorsListPage} />
          <PrivateRoute path="/donorlist" component={DonorsList} />
          <PrivateRoute path="/caselist" component={CaseList} />
          <PrivateRoute path="/members" component={AllMembers} />
          <PrivateRoute path="/trial" component={TrialBalance} />
          <PrivateRoute path="/journal" component={JournalPage} />
          <PrivateRoute path="/journallist" component={JournalList} />
          <PrivateRoute path="/test" component={Test} />
          <PrivateRoute path="/hmrcdonors" component={GiftAidDonorsList} />
        </Router>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps)(App);
