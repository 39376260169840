import React from "react";
import { ReactComponent as Loader } from "../../src/assets/loader.svg";

const LoadingButton = ({ onSubmit, text, loading = false, disabled }) => {
  return (
    <button className="btn btn-info" onClick={onSubmit} disabled={disabled}>
      {!loading ? text : <Loader className="spinner" />}
    </button>
  );
};

export default LoadingButton;
