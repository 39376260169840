import { authHeader, config } from "../helpers";
import { fetchWithRetry } from "./fetchWithRetry";

// Generic Fetch Function
async function fetchData(url, options = {}) {
  try {
    const response = await fetch(url, options);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
}

// Handle Response
function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok || response.status === 400) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then(resolve).catch(reject);
      } else {
        resolve();
      }
    } else {
      response.text().then(reject);
    }
  });
}

// Handle Error
function handleError(error) {
  return Promise.reject(error.message || "An unknown error occurred.");
}

// Common Request Options
function getRequestOptions(method, body = null) {
  const headers = authHeader();
  if (body) {
    headers["Content-Type"] = "application/json";
  }
  return {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  };
}

// Define uploadService with improved functions
export const uploadService = {
  getYearToOpen: () =>
    fetchData(
      config.apiUrl + "api/accountancy/GetActiveYear",
      getRequestOptions("GET")
    ),
  getAllFinancialYears: () =>
    fetchData(
      config.apiUrl + "api/accountancy/ListYears",
      getRequestOptions("GET")
    ),
  openFinancialYear: (year) =>
    fetchData(
      config.apiUrl + "api/accountancy/openyear",
      getRequestOptions("POST", { financialYear: year })
    ),
  closeFinancialYear: (year, reference) =>
    fetchData(
      config.apiUrl + "api/accountancy/closeyear",
      getRequestOptions("POST", { financialYear: year, reference })
    ),
  GetCasesByParam: (accountCode, startDate, endDate) =>
    fetchData(
      config.apiUrl + "api/accountancy/CasesByParam",
      getRequestOptions("POST", {
        accountCode,
        startDate: new Date(startDate).toLocaleDateString("en-GB"),
        endDate: new Date(endDate).toLocaleDateString("en-GB"),
      })
    ),
  getUnrestrictedFundAccumulated: () =>
    fetchData(
      config.apiUrl + "api/Accountancy/GetUnrestricted",
      getRequestOptions("GET")
    ),
  deleteCase: (uploadId) =>
    fetchData(
      config.apiUrl + "api/maindata/DeleteData/" + uploadId,
      getRequestOptions("POST")
    ),
  getAllIncomeExpenditures: (startDate, endDate) =>
    fetchData(
      config.apiUrl + "api/accountancy/IncomeExp",
      getRequestOptions("POST", {
        startDate: new Date(startDate).toLocaleDateString("en-GB"),
        endDate: new Date(endDate).toLocaleDateString("en-GB"),
      })
    ),
  getMoneyAccount: () =>
    fetchData(
      config.apiUrl + "api/accountancy/GetAllMoneyAccount",
      getRequestOptions("GET")
    ),
  getTaxCode: () =>
    fetchData(
      config.apiUrl + "api/accountancy/GetAllTaxCodes",
      getRequestOptions("GET")
    ),
  createJournal: (formData) =>
    fetchData(
      config.apiUrl + "api/Accountancy/createjournal",
      getRequestOptions("POST", formData)
    ),
  getAllJournals: (startDate, endDate) =>
    fetchData(
      config.apiUrl + "api/Accountancy/GetAllTransactionJournals",
      getRequestOptions("POST", {
        startDate: new Date(startDate).toLocaleDateString("en-GB"),
        endDate: new Date(endDate).toLocaleDateString("en-GB"),
      })
    ),
  editJournal: (formData) =>
    fetchData(
      config.apiUrl + "api/Accountancy/EditTransactionalJournal",
      getRequestOptions("POST", formData)
    ),
  deleteJournal: (Id) =>
    fetchData(
      config.apiUrl + "api/Accountancy/DeleteTransactionJournal/" + Id,
      getRequestOptions("POST")
    ),
  getAllCasesForBarChart: (startDate, endDate) =>
    fetchData(
      config.apiUrl + "api/maindata/GetBarChartData",
      getRequestOptions("POST", {
        startDate: new Date(startDate).toLocaleDateString("en-GB"),
        endDate: new Date(endDate).toLocaleDateString("en-GB"),
      })
    ),
  getAllGiftAidCases: (startDate, endDate) =>
    fetchData(
      config.apiUrl + "api/maindata/ListToHmrc",
      getRequestOptions("POST", {
        startDate: new Date(startDate).toLocaleDateString("en-GB"),
        endDate: new Date(endDate).toLocaleDateString("en-GB"),
      })
    ),
  PostTrialBalanceData: (startDate, endDate) =>
    fetchData(
      config.apiUrl + "api/accountancy/trialbalance",
      getRequestOptions("POST", {
        startDate: new Date(startDate).toLocaleDateString("en-GB"),
        endDate: new Date(endDate).toLocaleDateString("en-GB"),
      })
    ),
  PostGiftAidToHmrc: (donorList, startDate, endDate) =>
    fetchData(
      config.apiUrl + "api/maindata/CreateXmlToHmrc",
      getRequestOptions("POST", {
        MainDataList: donorList,
        startDate: new Date(startDate).toLocaleDateString("en-GB"),
        endDate: new Date(endDate).toLocaleDateString("en-GB"),
      })
    ),
  getAllCases: () =>
    fetchData(
      config.apiUrl + "api/maindata/GetTop1000Cases",
      getRequestOptions("GET")
    ),
  getAllCasesByDate: (start, end) =>
    fetchData(
      config.apiUrl + "api/maindata/CasesByDate",
      getRequestOptions("POST", {
        date1: new Date(start).toLocaleDateString("en-GB"),
        date2: new Date(end).toLocaleDateString("en-GB"),
      })
    ),
  uploadCase: (formData) =>
    fetchData(
      config.apiUrl + "api/maindata/uploaddata",
      getRequestOptions("POST", formData)
    ),
  updateCase: (payload) =>
    fetchData(
      config.apiUrl + "api/maindata/updatedata",
      getRequestOptions("POST", payload)
    ),
  updateCaseAndJournal: (payload) =>
    fetchData(
      config.apiUrl + "api/maindata/UpdateCaseJournal",
      getRequestOptions("POST", payload)
    ),
  getCaseById: (uploadId) =>
    fetchData(
      config.apiUrl + "api/maindata/getcase/" + uploadId,
      getRequestOptions("GET")
    ),
  downloadCaseById: (uploadId) =>
    fetchData(
      config.apiUrl + "api/maindata/DownloadFile/" + uploadId,
      getRequestOptions("GET")
    ),
  getSpecificCase: (uploadId) =>
    fetchData(
      config.apiUrl + "api/maindata/GetSpecificCase/" + uploadId,
      getRequestOptions("GET")
    ),
  logout: () => localStorage.removeItem("user"),
  getAllMembers: () =>
    fetchData(
      config.apiUrl + "api/members/GetAllMembersToUi",
      getRequestOptions("GET")
    ),
  updateMember: (member) =>
    fetchData(
      config.apiUrl + "api/members/editmember",
      getRequestOptions("POST", member)
    ),
  createMember: (member) =>
    fetchData(
      config.apiUrl + "api/members/postmember",
      getRequestOptions("POST", member)
    ),
  deleteMember: (id) =>
    fetchData(
      config.apiUrl + "api/members/deletemember/" + id,
      getRequestOptions("POST")
    ),
  getFinancialyear: () =>
    fetchWithRetry(
      config.apiUrl + "api/Accountancy/GetFinancialYearList",
      getRequestOptions("GET")
    ).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      debugger;
      return response.json(); // Process the JSON response
    }),
  getAllDonors: () =>
    fetchWithRetry(
      config.apiUrl + "api/donorsdetails/GetAllDonorsToUi",
      getRequestOptions("GET")
    ).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Process the JSON response
    }),
  getAllDonorsWithPagination: (pageNo) =>
    fetchData(
      config.apiUrl + `api/donorsdetails/GetPaginationDonors?pageNo=${pageNo}`,
      getRequestOptions("GET")
    ),
  updateDonor: (donor) =>
    fetchData(
      config.apiUrl + "api/donorsdetails/editdonor",
      getRequestOptions("POST", donor)
    ),
  createDonor: (donor) =>
    fetchData(
      config.apiUrl + "api/donorsdetails/postdonor",
      getRequestOptions("POST", donor)
    ),
  deleteDonor: (id) =>
    fetchData(
      config.apiUrl + "api/donorsdetails/deletedonor/" + id,
      getRequestOptions("DELETE")
    ),
  gettaxCodes: () =>
    fetchWithRetry(
      config.apiUrl + "api/accountancy/getalltaxcodes",
      getRequestOptions("GET")
    ).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Process the JSON response
    }),
  getMoneyAccounts: () =>
    fetchWithRetry(
      config.apiUrl + "api/accountancy/getallmoneyaccount",
      getRequestOptions("GET")
    ).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Process the JSON response
    }),
  getBranches: () =>
    fetchWithRetry(
      config.apiUrl + "api/donorsdetails/getallcities",
      getRequestOptions("GET")
    ).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Process the JSON response
    }),
  getById: (id) =>
    fetchData(config.apiUrl + "/users/" + id, getRequestOptions("GET")),
  register: (user) =>
    fetchData(
      config.proxyUrl + (config.apiUrl + "/users/register"),
      getRequestOptions("POST", user)
    ),
  update: (user) =>
    fetchData(
      config.proxyUrl + (config.apiUrl + "/users/update"),
      getRequestOptions("POST", user)
    ),
};
