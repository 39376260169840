import React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  ColumnSeries,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";
import { isMobile } from "react-device-detect";

const SAMPLE_CSS = `
     .control-fluid {
         padding: 0px !important;
     }`;
const BarChart2 = (props) => {
  debugger;
  return (
    <div className="control-panel">
      <style>{SAMPLE_CSS}</style>
      <div className="control-section">
        <ChartComponent
          id="bar-charts2"
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{
            labelIntersectAction: Browser.isDevice ? "None" : "Trim",
            labelRotation: Browser.isDevice ? -45 : 0,
            valueType: "Category",
            interval: 1,
            majorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            edgeLabelPlacement: "Shift",
          }}
          primaryYAxis={{
            title: "Amount Per Year",
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            maximum: 100000,
            interval: 5000,
          }}
          chartArea={{ border: { width: 0 } }}
          load={load.bind(this)}
          tooltip={{
            enable: true,
            header: "<b>${point.tooltip}</b>",
            shared: true,
          }}
          width={isMobile ? "75%" : "100%"}
          title="Gift Aid - Expenditure - Non-Gift Aid Data - Current Financial Year"
          loaded={loaded.bind(this)}
        >
          <Inject
            services={[
              ColumnSeries,
              Legend,
              Tooltip,
              Category,
              DataLabel,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={props.gaCases}
              tooltipMappingName="toolTipMappingName"
              xName="x"
              columnSpacing={0.1}
              yName="y"
              name="Gift Aid"
              type="Column"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={props.expCases}
              tooltipMappingName="toolTipMappingName"
              xName="x"
              columnSpacing={0.1}
              yName="y"
              name="Expenditure"
              type="Column"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={props.nogaCases}
              tooltipMappingName="toolTipMappingName"
              xName="x"
              columnSpacing={0.1}
              yName="y"
              name="Non-Gift Aid"
              type="Column"
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
  function loaded(args) {
    let chart = document.getElementById("bar-charts");
    chart.setAttribute("title", "");
  }
  function load(args) {
    let selectedTheme = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    ).replace(/-dark/i, "Dark");
    if (selectedTheme === "highcontrast") {
      args.chart.series[0].marker.dataLabel.font.color = "#000000";
      args.chart.series[1].marker.dataLabel.font.color = "#000000";
      args.chart.series[2].marker.dataLabel.font.color = "#000000";
    }
  }
};
export default BarChart2;
