import { userConstants } from "../constants";
import { userService } from "../services";
//import { userService } from "../api/index";
import { alertActions } from "./";
import { history } from "../helpers";
import SLUGS from "../Resources/slugs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const userActions = {
  resetPassword,
  getPasswordCode,
  login,
  logout,
  register,
  getAll,
  delete: _delete,
};

function resetPassword(password, email) {
  return (dispatch) => {
    dispatch(request({ password, email }));

    userService.resetPassword(password, email).then(
      (user) => {
        debugger;
        dispatch(success(user));
        history.push(SLUGS.homepage);
        dispatch(alertActions.success("Password reset successful"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.RESETPASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESETPASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESETPASSWORD_FAILURE, error };
  }
}

function getPasswordCode(starDate, email) {
  return (dispatch) => {
    dispatch(request({ starDate, email }));

    userService.passwordCode(starDate, email).then(
      (codePayload) => {
        debugger;
        dispatch(success(codePayload));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(codePayload) {
    return { type: userConstants.CODE_REQUEST, codePayload };
  }
  function success(codePayload) {
    return { type: userConstants.CODE_SUCCESS, codePayload };
  }
  function failure(error) {
    return { type: userConstants.CODE_FAILURE, error };
  }
}

function login(username, password) {
  debugger;
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        debugger;
        if (user !== "Invalid User") {
          dispatch(success(user));
          dispatch(alertActions.success(user));
          toast.success("Login successful", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push(SLUGS.homepage);
        } else {
          dispatch(failure(user));
          dispatch(alertActions.error(user));
          toast.error("Invalid Username or Paswword ", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push(SLUGS.login);
        }
      },
      (error) => {
        debugger;
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(SLUGS.login);
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      () => {
        dispatch(success());
        history.push(SLUGS.login);
        dispatch(alertActions.success("Registration successful"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      () => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
