import React, { useState, useEffect } from "react";
import logo from "../assets/images/church-logo.png";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
//import app from "../data/base";
import { userService } from "../services/user.service";

const ResetPasswordPage = ({ history }) => {
  debugger;
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [password, setNewPassword] = useState("");
  const [expiredEmail, setExpiredEmail] = useState(false);
  const dateNow = new Date();
  const getEmailCreatedDate = new Date(
    localStorage.getItem("dateEmailCreated")
  );
  getEmailCreatedDate.setMinutes(getEmailCreatedDate.getMinutes() + 2);

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    if (dateNow < getEmailCreatedDate) {
      setExpiredEmail(false);
    } else {
      setExpiredEmail(true);
    }
  }, [dateNow, getEmailCreatedDate, value]);

  const resetPassword = async () => {
    try {
      await userService.newPassword(password).then((response) => {
        debugger;
        if (response.token) {
          localStorage.setItem("token", response.token);
          localStorage.setItem("refreshToken", response.refreshToken);
          setValue(man);
          history.push(`${process.env.PUBLIC_URL}/`);
        }
      });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  return (
    <div
      col-sm-8
      col-offset-sm-2
      col-md-6
      col-offset-md-4
      col-lg-6
      col-offset-lg-4
    >
      <div className="page-wrapper form_login">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>RESET PASSWORD</h4>
                          <h6>Enter your New Password </h6>
                        </div>
                        {!expiredEmail ? (
                          <form className="theme-form">
                            <div className="form-group">
                              <label className="col-form-label">Password</label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                              {/* {errors.password && 'Email is required'} */}
                            </div>
                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={() => resetPassword()}
                              >
                                Reset Password
                              </button>
                            </div>
                          </form>
                        ) : (
                          <div className="text-center">
                            <h4>RESET PASSWORD LINK</h4>
                            <h6>
                              Sorry but the reset password link has expired, try
                              it again please.
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
