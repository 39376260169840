import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useHistory } from "react-router-dom";
import SLUGS from "../../Resources/slugs";
import {
  IconAgents,
  IconArticles,
  IconContacts,
  IconIdeas,
  IconLogout,
  IconOverview,
  IconSettings,
  IconSubscription,
} from "../../assets/icons";
import { convertSlugToUrl } from "../../Resources/utilities";
import LogoComponent from "./LogoComponent";
import Menu from "./MenuComponent";
import MenuItem from "./MenuItemComponent";

const useStyles = createUseStyles({
  separator: {
    borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
    marginTop: 16,
    marginBottom: 16,
    opacity: 0.06,
  },
});

function SidebarComponent() {
  const { push } = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isMobile = window.innerWidth <= 1080;

  async function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("sumTotal");
    localStorage.removeItem("sumUnrestrictedPeriod");
    localStorage.removeItem("balanceResults");
    localStorage.removeItem("sumUnrestrictedAccumulated");

    push(SLUGS.login);
  }

  function onClick(slug, parameters = {}) {
    if (slug === "/accountancy/trial" || slug === "/caseDetails/caselist") {
      push(convertSlugToUrl(slug, parameters));
      // window.location.reload();
    } else {
      push(convertSlugToUrl(slug, parameters));
    }
  }

  return (
    <Menu isMobile={isMobile}>
      <div style={{ paddingTop: 30, paddingBottom: 30 }}>
        <LogoComponent />
      </div>
      <MenuItem
        id={SLUGS.dashboard}
        title="Dashboard"
        icon={IconSubscription}
        onClick={() => onClick(SLUGS.dashboard)}
      />
      <MenuItem
        id={SLUGS.homepage}
        title="Home"
        icon={IconSubscription}
        onClick={() => onClick(SLUGS.dashboardHome)}
      />
      <MenuItem
        id={SLUGS.donorDetails}
        items={[SLUGS.donorlist, SLUGS.members]}
        title="Donors"
        icon={IconOverview}
      >
        <MenuItem
          id={SLUGS.donorlist}
          title="Donors"
          level={2}
          icon={IconArticles}
          onClick={() => onClick(SLUGS.donorlist)}
        />
        <MenuItem
          id={SLUGS.members}
          title="Members"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.members)}
        />
        <MenuItem
          id={SLUGS.createMembers}
          title="Create Members"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.createMembers)}
        />
      </MenuItem>
      <MenuItem
        id={SLUGS.caseDetails}
        items={[SLUGS.caselist, SLUGS.upload]}
        title="Cases"
        icon={IconIdeas}
      >
        <MenuItem
          id={SLUGS.caselist}
          title="List"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.caselist)}
        />
        <MenuItem
          id={SLUGS.upload}
          title="Upload"
          level={2}
          icon={IconArticles}
          onClick={() => onClick(SLUGS.upload)}
        />
      </MenuItem>
      <MenuItem
        id={SLUGS.accountancy}
        items={[
          SLUGS.trial,
          SLUGS.balanceSheet,
          SLUGS.journal,
          SLUGS.journallist,
          SLUGS.hmrcdonors,
          SLUGS.incexpreport,
        ]}
        title="Accountancy"
        icon={IconContacts}
        onClick={() => onClick(SLUGS.accountancy)}
      >
        <MenuItem
          id={SLUGS.trial}
          title="Trial"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.trial)}
        />
        <MenuItem
          id={SLUGS.balanceSheet}
          title="BalanceSheet"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.balanceSheet)}
        />
        <MenuItem
          id={SLUGS.journal}
          title="Journal"
          level={2}
          icon={IconArticles}
          onClick={() => onClick(SLUGS.journal)}
        />
        <MenuItem
          id={SLUGS.journallist}
          title="List"
          level={2}
          icon={IconSubscription}
          onClick={() => onClick(SLUGS.journallist)}
        />
        <MenuItem
          id={SLUGS.hmrcdonors}
          title="HMRC"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.hmrcdonors)}
        />
        <MenuItem
          id={SLUGS.incexpreport}
          title="Income_Exp"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.incexpreport)}
        />
        {/*    <MenuItem
          id={SLUGS.openclose}
          title="Open/Close"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.openclose)}
        /> */}
        {/*  <MenuItem
          id={SLUGS.test}
          title="Test"
          level={2}
          icon={IconAgents}
          onClick={() => onClick(SLUGS.test)}
        /> */}
      </MenuItem>

      <div className={classes.separator}></div>
      <MenuItem
        id={SLUGS.settings}
        title="Settings"
        icon={IconSettings}
        onClick={() => onClick(SLUGS.settings)}
      />
      <MenuItem id="logout" title="Logout" icon={IconLogout} onClick={logout} />
    </Menu>
  );
}

export default SidebarComponent;
