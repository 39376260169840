import React, { useState, useEffect, useCallback } from "react";
import MembersTableContent from "../Components/TableContent/MembersTableContent";
import { uploadService } from "../services/data.service";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import LoadingSpinner from "../Components/LoadingSpinner";

const AllMembers = () => {
  const [members, setMembers] = useState([]);

  const MembersList = useCallback(() => {
    const listMembers = async () => {
      debugger;
      await uploadService.getAllMembers().then((response) => {
        if (response.length > 0) {
          setMembers(response);
        } else {
        }
      });
    };
    listMembers();
  }, []);

  useEffect(() => {
    const AllData = setTimeout(() => {
      MembersList();
    }, 500);

    return () => clearTimeout(AllData);
  }, [MembersList, members]);

  return (
    <div>
      <>
        {members.length > 0 ? (
          <div>
            <div className="pagecontainer">
              <div className="container-fluid p-0">
                <h2>List of Members</h2>
                <Container>
                  <MembersTableContent members={members} />
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ TextAlign: "center" }}>
            <LoadingSpinner
              style={{
                flex: 1,
                MarginTop: 240,
                JustifyContent: "center",
                AlignItems: "center",
              }}
            />
          </div>
        )}
      </>
      <ToastContainer />
    </div>
  );
};

export default AllMembers;
