import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function TableHeader(props) {
  debugger;
  const { valueToOrderBy, orderDirection, handleRequestSort, StyledTableCell } =
    props;

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell key="firstname">
          <TableSortLabel
            active={valueToOrderBy === "firstname"}
            direction={valueToOrderBy === "firstname" ? orderDirection : "asc"}
            onClick={createSortHandler("firstname")}
          >
            First Name
          </TableSortLabel>
        </StyledTableCell>

        <StyledTableCell key="lastname">
          <TableSortLabel
            active={valueToOrderBy === "lastname"}
            direction={valueToOrderBy === "lastname" ? orderDirection : "asc"}
            onClick={createSortHandler("lastname")}
          >
            Last Name
          </TableSortLabel>
        </StyledTableCell>
        <StyledTableCell key="houseNo">Last Name</StyledTableCell>
        <StyledTableCell key="postcode">Postcode</StyledTableCell>
        <StyledTableCell key="fullname">Full Name</StyledTableCell>
        <StyledTableCell>Edit</StyledTableCell>
        <StyledTableCell>Delete</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
