import React, { useState, useEffect, useCallback } from "react";
import CasesTableContent from "../Components/TableContent/CasesTableContent";
import { uploadService } from "../services/data.service";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import LoadingSpinner from "../Components/LoadingSpinner";
import OpenCloseFinancialYear from "../Components/OpenCloseYear";

const start = localStorage.getItem("startDate")
  ? new Date(parseInt(localStorage.getItem("startDate")))
  : null;
const end = localStorage.getItem("endDate")
  ? new Date(parseInt(localStorage.getItem("endDate")))
  : null;

const ListCases = () => {
  const newStart =
    start instanceof Date ? start : OpenCloseFinancialYear().start;
  const newEnd = end instanceof Date ? end : OpenCloseFinancialYear().end;

  const [cases, setCases] = useState([]);

  const caseList = useCallback(async () => {
    await uploadService.getAllCasesByDate(newStart, newEnd).then((response) => {
      setCases(response);
    });
  }, [newEnd, newStart]);

  useEffect(() => {
    const listData = setTimeout(() => {
      caseList();
    }, 500);
    return () => clearTimeout(listData);
  }, [caseList]);

  return (
    <div>
      <>
        {cases && cases.length > 0 ? (
          <div>
            <div className="pagecontainer">
              <div className="container-fluid p-0">
                <h2 className="text-center">List of Cases</h2>
                <Container>
                  <CasesTableContent
                    cases={cases}
                    startDate={newStart}
                    endDate={newEnd}
                  />
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ TextAlign: "center" }}>
            <LoadingSpinner
              style={{
                flex: 1,
                MarginTop: 240,
                JustifyContent: "center",
                AlignItems: "center",
              }}
            />
          </div>
        )}
      </>
      <ToastContainer />
    </div>
  );
};

export default ListCases;
