export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  CODE_REQUEST: "CODE_REQUEST",
  CODE_SUCCESS: "CODE_SUCCESS",
  CODE_FAILURE: "CODE_FAILURE",

  RESETPASSWORD_REQUEST: "RESETPASSWORD_REQUEST",
  RESETPASSWORD_SUCCESS: "RESETPASSWORD_SUCCESS",
  RESETPASSWORD_FAILURE: "RESETPASSWORD_FAILURE",
};
